import { postToSalesforce } from "services/salesforceService";
import styles from "../../../DeselectorEmailCapture/DeselectorEmailCapture.module.scss";

const DeselectorEmailCaptureConfig = (props) => ({
  captcha: true,
  service: postToSalesforce,
  form: {
    layout: "list",
    theme: "light",
    fields: [
      {
        type: "firstName",
        params: {
          label: "First Name",
          name: "first_name",
          required: true,
          addtClassName: `${styles.testfname}`,
        },
      },
      {
        type: "lastName",
        params: {
          label: "Last Name",
          name: "last_name",
          required: true,
          addtClassName: `${styles.testfname}`,
        },
      },
      {
        type: "email",
        params: {
          label: "Email Address",
          name: "email",
          required: true,
          addtClassName: `${styles.deselectorEmail}`,
        },
      },
      {
        type: "checkbox-group",
        params: {
          //   label: 'Type of Services Received',
          name: "deselectorCheckbox",
          options: [
            {
              value: props?.componentProperties?.consentCheckbox,
              label: props?.componentProperties?.consentCheckbox,
            },
            {
              value: props?.componentProperties?.contactCheckbox,
              label: props?.componentProperties?.contactCheckbox,
            },
          ],
          //required: true,
          defaultChecked: true,
          addtClassName: `${styles.deselectorEmailCaptureCheckbox}`,
        },
      },
    ],
    buttons: [
      {
        type: "submit",
        params: {
          label: "Submit",
          theme: {
            style: "primary",
            type: "button",
          },
          // addtClassName: `${styles.testfname}`,
        },
      },
    ],
  },
});

export default DeselectorEmailCaptureConfig;
