export default <svg
width={26}
height={26}
viewBox="0 0 26 26"
fill="none"
xmlns="http://www.w3.org/2000/svg"

>
<path
  d="M13 1L13 25"
  stroke="black"
  strokeWidth={2}
  strokeLinecap="round"
/>
<path
  d="M25 13L1 13"
  stroke="black"
  strokeWidth={2}
  strokeLinecap="round"
/>
</svg>