
const AramarkHospitalityIq = ({ logoAltText }) => (
<svg version="1.1" viewBox="416.84 353.16 396.27 106.65">
                                            <circle xmlns="http://www.w3.org/2000/svg" fill="#ea0029" cx="781.87" cy="383.4" r="28.24" id="circle1"/>
                                            <title>{ logoAltText }</title>
                                            <path xmlns="http://www.w3.org/2000/svg" fill="#fff" d="m765.07,372.06h5.25v23.87h-5.25v-23.87Z" id="path1"/>
                                            <path xmlns="http://www.w3.org/2000/svg" fill="#fff" d="m797.36,396.64l-2.63-2.35c-2.05,1.33-4.4,2.05-7.09,2.05-7.37,0-12.65-5.49-12.65-12.28v-.07c0-6.79,5.35-12.35,12.72-12.35s12.65,5.49,12.65,12.28v.07c0,2.56-.78,4.84-2.11,6.85l2.46,2.08-3.34,3.72Zm-6.55-5.8l-3.85-3.31,3.34-3.75,3.92,3.55c.44-.99.65-2.08.65-3.27v-.07c0-4.09-3-7.5-7.23-7.5s-7.16,3.34-7.16,7.43v.07c0,4.09,3,7.5,7.23,7.5,1.19,0,2.18-.24,3.1-.65Z" id="path2"/>
                                            <path d="m427.28,362.8h7.66v18.13h20.81v-18.13h7.66v43.61h-7.66v-18.38h-20.81v18.38h-7.66v-43.61Z" id="path3"/>
                                            <path d="m468.71,390.15v-.12c0-9.41,7.48-17.2,17.57-17.2s17.51,7.66,17.51,17.07v.12c0,9.35-7.48,17.13-17.63,17.13s-17.44-7.66-17.44-17.01Zm27.54,0v-.12c0-5.79-4.17-10.59-10.09-10.59s-9.91,4.73-9.91,10.47v.12c0,5.73,4.17,10.53,10.03,10.53s9.97-4.74,9.97-10.4Z" id="path4"/>
                                            <path d="m506.76,402.11l3.36-5.11c3.61,2.68,7.41,4.05,10.78,4.05s5.11-1.37,5.11-3.55v-.12c0-2.55-3.49-3.43-7.35-4.61-4.86-1.37-10.28-3.36-10.28-9.66v-.12c0-6.23,5.17-10.03,11.71-10.03,4.11,0,8.6,1.43,12.09,3.74l-2.99,5.36c-3.18-1.93-6.54-3.12-9.28-3.12-2.93,0-4.61,1.37-4.61,3.24v.12c0,2.43,3.55,3.43,7.41,4.67,4.8,1.5,10.22,3.68,10.22,9.59v.12c0,6.92-5.36,10.34-12.21,10.34-4.67,0-9.84-1.68-13.96-4.92Z" id="path5"/>
                                            <path d="m538.99,373.52h7.54v5.48c2.43-3.43,5.86-6.17,11.21-6.17,7.73,0,15.26,6.11,15.26,17.07v.12c0,10.9-7.48,17.07-15.26,17.07-5.48,0-8.91-2.74-11.21-5.79v15.08h-7.54v-42.86Zm26.35,16.51v-.12c0-6.35-4.3-10.53-9.41-10.53s-9.59,4.24-9.59,10.53v.12c0,6.29,4.49,10.53,9.59,10.53s9.41-4.05,9.41-10.53Z" id="path6"/>
                                            <path d="m578.95,361.31h8.1v7.16h-8.1v-7.16Zm.31,12.21h7.54v32.9h-7.54v-32.9Z" id="path7"/>
                                            <path d="m596.07,397.32v-17.32h-4.17v-6.48h4.17v-9.03h7.54v9.03h8.85v6.48h-8.85v16.14c0,2.93,1.5,4.11,4.05,4.11,1.68,0,3.18-.37,4.67-1.12v6.17c-1.87,1.06-3.92,1.68-6.79,1.68-5.54,0-9.47-2.43-9.47-9.66Z" id="path8"/>
                                            <path d="m615.95,390.03v-.12c0-10.9,7.41-17.07,15.26-17.07,5.42,0,8.85,2.68,11.21,5.79v-5.11h7.54v32.9h-7.54v-5.48c-2.43,3.43-5.86,6.17-11.21,6.17-7.73,0-15.26-6.17-15.26-17.07Zm26.6,0v-.12c0-6.35-4.49-10.53-9.53-10.53s-9.47,3.99-9.47,10.53v.12c0,6.35,4.36,10.53,9.47,10.53s9.53-4.24,9.53-10.53Z" id="path9"/>
                                            <path d="m658.35,360.93h7.54v45.48h-7.54v-45.48Z" id="path10"/>
                                            <path d="m673.73,361.31h8.1v7.16h-8.1v-7.16Zm.31,12.21h7.54v32.9h-7.54v-32.9Z" id="path11"/>
                                            <path d="m690.85,397.32v-17.32h-4.17v-6.48h4.17v-9.03h7.54v9.03h8.85v6.48h-8.85v16.14c0,2.93,1.5,4.11,4.05,4.11,1.68,0,3.18-.37,4.67-1.12v6.17c-1.87,1.06-3.92,1.68-6.79,1.68-5.54,0-9.47-2.43-9.47-9.66Z" id="path12"/>
                                            <path d="m736.6,373.52h7.85l-13.08,33.83c-2.62,6.73-5.54,9.22-10.84,9.22-3.12,0-5.48-.75-7.79-1.99l2.55-5.61c1.37.75,2.87,1.25,4.42,1.25,2.12,0,3.43-.93,4.67-3.74l-13.71-32.96h8.1l9.28,24.42,8.54-24.42Z" id="path13"/>
                                            <path d="m426.84,430.72h2.58v14.68h-2.58v-14.68Z" id="path14"/>
                                            <path d="m433.01,434.32h2.54v1.7c.71-1.03,1.74-1.93,3.46-1.93,2.49,0,3.94,1.68,3.94,4.24v7.07h-2.54v-6.29c0-1.72-.86-2.7-2.37-2.7s-2.49,1.03-2.49,2.75v6.25h-2.54v-11.07Z" id="path15"/>
                                            <path d="m446.36,442.33v-5.83h-1.4v-2.18h1.4v-3.04h2.54v3.04h2.98v2.18h-2.98v5.43c0,.99.5,1.38,1.36,1.38.57,0,1.07-.13,1.57-.38v2.08c-.63.36-1.32.57-2.29.57-1.87,0-3.19-.82-3.19-3.25Z" id="path16"/>
                                            <path d="m453.47,439.9v-.04c0-3.17,2.24-5.77,5.41-5.77,3.52,0,5.3,2.77,5.3,5.96,0,.23-.02.46-.04.71h-8.14c.27,1.8,1.55,2.81,3.19,2.81,1.24,0,2.12-.46,3-1.32l1.49,1.32c-1.05,1.26-2.49,2.08-4.53,2.08-3.21,0-5.68-2.33-5.68-5.74Zm8.2-.82c-.17-1.64-1.13-2.92-2.81-2.92-1.55,0-2.64,1.2-2.87,2.92h5.68Z" id="path17"/>
                                            <path d="m466.82,430.09h2.54v15.31h-2.54v-15.31Z" id="path18"/>
                                            <path d="m472.63,430.09h2.54v15.31h-2.54v-15.31Z" id="path19"/>
                                            <path d="m478.35,430.21h2.73v2.41h-2.73v-2.41Zm.1,4.11h2.54v11.07h-2.54v-11.07Z" id="path20"/>
                                            <path d="m484.08,447.47l.94-1.91c1.2.73,2.43,1.17,3.94,1.17,2.26,0,3.5-1.17,3.5-3.4v-.86c-.92,1.19-2.08,2.03-3.9,2.03-2.6,0-5.03-1.93-5.03-5.18v-.04c0-3.27,2.45-5.2,5.03-5.2,1.87,0,3.02.86,3.88,1.89v-1.66h2.54v8.81c0,1.87-.48,3.25-1.41,4.17-1.01,1.01-2.56,1.49-4.55,1.49-1.82,0-3.52-.46-4.95-1.32Zm8.41-8.16v-.04c0-1.8-1.49-3.04-3.25-3.04s-3.15,1.22-3.15,3.04v.04c0,1.8,1.41,3.04,3.15,3.04s3.25-1.24,3.25-3.04Z" id="path21"/>
                                            <path d="m497.55,439.9v-.04c0-3.17,2.24-5.77,5.41-5.77,3.52,0,5.3,2.77,5.3,5.96,0,.23-.02.46-.04.71h-8.14c.27,1.8,1.55,2.81,3.19,2.81,1.24,0,2.12-.46,3-1.32l1.49,1.32c-1.05,1.26-2.49,2.08-4.53,2.08-3.21,0-5.68-2.33-5.68-5.74Zm8.2-.82c-.17-1.64-1.13-2.92-2.81-2.92-1.55,0-2.64,1.2-2.87,2.92h5.68Z" id="path22"/>
                                            <path d="m510.81,434.32h2.54v1.7c.71-1.03,1.74-1.93,3.46-1.93,2.49,0,3.94,1.68,3.94,4.24v7.07h-2.54v-6.29c0-1.72-.86-2.7-2.37-2.7s-2.49,1.03-2.49,2.75v6.25h-2.54v-11.07Z" id="path23"/>
                                            <path d="m523.17,439.92v-.04c0-3.14,2.43-5.79,5.77-5.79,2.08,0,3.38.78,4.42,1.91l-1.59,1.7c-.78-.82-1.59-1.38-2.85-1.38-1.84,0-3.21,1.59-3.21,3.52v.04c0,1.97,1.36,3.54,3.31,3.54,1.2,0,2.08-.55,2.87-1.36l1.53,1.51c-1.09,1.22-2.37,2.08-4.53,2.08-3.29,0-5.72-2.58-5.72-5.72Z" id="path24"/>
                                            <path d="m534.77,439.9v-.04c0-3.17,2.24-5.77,5.41-5.77,3.52,0,5.3,2.77,5.3,5.96,0,.23-.02.46-.04.71h-8.14c.27,1.8,1.55,2.81,3.19,2.81,1.24,0,2.12-.46,3-1.32l1.49,1.32c-1.05,1.26-2.49,2.08-4.53,2.08-3.21,0-5.68-2.33-5.68-5.74Zm8.2-.82c-.17-1.64-1.13-2.92-2.81-2.92-1.55,0-2.64,1.2-2.87,2.92h5.68Z" id="path25"/>
                                            <path d="m556.88,443.68v1.72h-2.54v-15.31h2.54v6.08c.82-1.15,1.97-2.08,3.77-2.08,2.6,0,5.14,2.05,5.14,5.75v.04c0,3.67-2.52,5.75-5.14,5.75-1.85,0-3-.92-3.77-1.95Zm6.33-3.8v-.04c0-2.14-1.45-3.54-3.17-3.54s-3.23,1.43-3.23,3.54v.04c0,2.12,1.51,3.54,3.23,3.54s3.17-1.36,3.17-3.54Z" id="path26"/>
                                            <path d="m567.77,439.9v-.04c0-3.17,2.24-5.77,5.41-5.77,3.52,0,5.3,2.77,5.3,5.96,0,.23-.02.46-.04.71h-8.14c.27,1.8,1.55,2.81,3.19,2.81,1.24,0,2.12-.46,3-1.32l1.49,1.32c-1.05,1.26-2.49,2.08-4.53,2.08-3.21,0-5.68-2.33-5.68-5.74Zm8.2-.82c-.17-1.64-1.13-2.92-2.81-2.92-1.55,0-2.64,1.2-2.87,2.92h5.68Z" id="path27"/>
                                            <path d="m581.03,430.09h2.54v5.93c.71-1.03,1.74-1.93,3.46-1.93,2.49,0,3.94,1.68,3.94,4.24v7.07h-2.54v-6.29c0-1.72-.86-2.7-2.37-2.7s-2.49,1.03-2.49,2.75v6.25h-2.54v-15.31Z" id="path28"/>
                                            <path d="m593.99,430.21h2.73v2.41h-2.73v-2.41Zm.1,4.11h2.54v11.07h-2.54v-11.07Z" id="path29"/>
                                            <path d="m599.84,434.32h2.54v1.7c.71-1.03,1.74-1.93,3.46-1.93,2.49,0,3.94,1.68,3.94,4.24v7.07h-2.54v-6.29c0-1.72-.86-2.7-2.37-2.7s-2.49,1.03-2.49,2.75v6.25h-2.54v-11.07Z" id="path30"/>
                                            <path d="m612.26,439.88v-.04c0-3.67,2.49-5.75,5.14-5.75,1.82,0,2.98.9,3.77,1.95v-5.96h2.54v15.31h-2.54v-1.85c-.82,1.15-1.97,2.08-3.77,2.08-2.6,0-5.14-2.08-5.14-5.75Zm8.95,0v-.04c0-2.14-1.51-3.54-3.21-3.54s-3.19,1.34-3.19,3.54v.04c0,2.14,1.47,3.54,3.19,3.54s3.21-1.43,3.21-3.54Z" id="path31"/>
                                            <path d="m632.58,439.9v-.04c0-3.17,2.24-5.77,5.41-5.77,3.52,0,5.31,2.77,5.31,5.96,0,.23-.02.46-.04.71h-8.14c.27,1.8,1.55,2.81,3.19,2.81,1.24,0,2.12-.46,3-1.32l1.49,1.32c-1.05,1.26-2.49,2.08-4.53,2.08-3.21,0-5.68-2.33-5.68-5.74Zm8.2-.82c-.17-1.64-1.13-2.92-2.81-2.92-1.55,0-2.64,1.2-2.87,2.92h5.68Z" id="path32"/>
                                            <path d="m644.2,434.32h2.73l2.98,8.13,3-8.13h2.66l-4.53,11.15h-2.29l-4.55-11.15Z" id="path33"/>
                                            <path d="m656.47,439.9v-.04c0-3.17,2.24-5.77,5.41-5.77,3.52,0,5.3,2.77,5.3,5.96,0,.23-.02.46-.04.71h-8.14c.27,1.8,1.55,2.81,3.19,2.81,1.24,0,2.12-.46,3-1.32l1.49,1.32c-1.05,1.26-2.49,2.08-4.53,2.08-3.21,0-5.68-2.33-5.68-5.74Zm8.2-.82c-.17-1.64-1.13-2.92-2.81-2.92-1.55,0-2.64,1.2-2.87,2.92h5.68Z" id="path34"/>
                                            <path d="m669.73,434.32h2.54v2.49c.69-1.66,1.97-2.79,3.92-2.71v2.68h-.15c-2.22,0-3.77,1.45-3.77,4.38v4.21h-2.54v-11.07Z" id="path35"/>
                                            <path d="m686.4,434.32h2.64l-4.4,11.39c-.88,2.26-1.87,3.1-3.65,3.1-1.05,0-1.85-.25-2.62-.67l.86-1.89c.46.25.96.42,1.49.42.71,0,1.15-.31,1.57-1.26l-4.61-11.09h2.73l3.12,8.22,2.87-8.22Z" id="path36"/>
                                            <path d="m696.91,439.9v-.04c0-3.17,2.24-5.77,5.41-5.77,3.52,0,5.31,2.77,5.31,5.96,0,.23-.02.46-.04.71h-8.14c.27,1.8,1.55,2.81,3.19,2.81,1.24,0,2.12-.46,3-1.32l1.49,1.32c-1.05,1.26-2.49,2.08-4.53,2.08-3.21,0-5.68-2.33-5.68-5.74Zm8.2-.82c-.17-1.64-1.13-2.92-2.81-2.92-1.55,0-2.64,1.2-2.87,2.92h5.68Z" id="path37"/>
                                            <path d="m714.1,438.03l2.56-3.71h2.7l-3.9,5.41,4.07,5.66h-2.77l-2.73-3.94-2.7,3.94h-2.7l4.05-5.64-3.9-5.43h2.77l2.56,3.71Z" id="path38"/>
                                            <path d="m721.55,434.32h2.54v1.84c.82-1.15,1.97-2.08,3.77-2.08,2.6,0,5.14,2.05,5.14,5.75v.04c0,3.67-2.52,5.75-5.14,5.75-1.85,0-3-.92-3.77-1.95v5.07h-2.54v-14.43Zm8.87,5.56v-.04c0-2.14-1.45-3.54-3.17-3.54s-3.23,1.43-3.23,3.54v.04c0,2.12,1.51,3.54,3.23,3.54s3.17-1.36,3.17-3.54Z" id="path39"/>
                                            <path d="m734.99,439.9v-.04c0-3.17,2.24-5.77,5.41-5.77,3.52,0,5.31,2.77,5.31,5.96,0,.23-.02.46-.04.71h-8.14c.27,1.8,1.55,2.81,3.19,2.81,1.24,0,2.12-.46,3-1.32l1.49,1.32c-1.05,1.26-2.49,2.08-4.53,2.08-3.21,0-5.68-2.33-5.68-5.74Zm8.2-.82c-.17-1.64-1.13-2.92-2.81-2.92-1.55,0-2.64,1.2-2.87,2.92h5.68Z" id="path40"/>
                                            <path d="m748.25,434.32h2.54v2.49c.69-1.66,1.97-2.79,3.92-2.71v2.68h-.15c-2.22,0-3.77,1.45-3.77,4.38v4.21h-2.54v-11.07Z" id="path41"/>
                                            <path d="m756.68,430.21h2.73v2.41h-2.73v-2.41Zm.1,4.11h2.54v11.07h-2.54v-11.07Z" id="path42"/>
                                            <path d="m761.88,439.9v-.04c0-3.17,2.24-5.77,5.41-5.77,3.52,0,5.31,2.77,5.31,5.96,0,.23-.02.46-.04.71h-8.14c.27,1.8,1.55,2.81,3.19,2.81,1.24,0,2.12-.46,3-1.32l1.49,1.32c-1.05,1.26-2.49,2.08-4.53,2.08-3.21,0-5.68-2.33-5.68-5.74Zm8.2-.82c-.17-1.64-1.13-2.92-2.81-2.92-1.55,0-2.64,1.2-2.87,2.92h5.68Z" id="path43"/>
                                            <path d="m775.14,434.32h2.54v1.7c.71-1.03,1.74-1.93,3.46-1.93,2.49,0,3.94,1.68,3.94,4.24v7.07h-2.54v-6.29c0-1.72-.86-2.7-2.37-2.7s-2.49,1.03-2.49,2.75v6.25h-2.54v-11.07Z" id="path44"/>
                                            <path d="m787.5,439.92v-.04c0-3.14,2.43-5.79,5.77-5.79,2.08,0,3.38.78,4.42,1.91l-1.59,1.7c-.78-.82-1.59-1.38-2.85-1.38-1.85,0-3.21,1.59-3.21,3.52v.04c0,1.97,1.36,3.54,3.31,3.54,1.2,0,2.08-.55,2.87-1.36l1.53,1.51c-1.09,1.22-2.37,2.08-4.53,2.08-3.29,0-5.72-2.58-5.72-5.72Z" id="path45"/>
                                            <path d="m799.11,439.9v-.04c0-3.17,2.24-5.77,5.41-5.77,3.52,0,5.3,2.77,5.3,5.96,0,.23-.02.46-.04.71h-8.14c.27,1.8,1.55,2.81,3.19,2.81,1.24,0,2.12-.46,3-1.32l1.49,1.32c-1.05,1.26-2.49,2.08-4.53,2.08-3.21,0-5.68-2.33-5.68-5.74Zm8.2-.82c-.17-1.64-1.13-2.92-2.81-2.92-1.55,0-2.64,1.2-2.87,2.92h5.68Z" id="path46"/>
                             </svg>
                             );



const AramarkHospitalityIqBlackAndWhite =({ logoAltText }) => (
 <svg version="1.1" viewBox="416.84 353.16 396.27 106.65">
                                                         <circle xmlns="http://www.w3.org/2000/svg" cx="781.87" cy="383.4" r="28.24" id="circle1"/>
                                                         <title>{ logoAltText }</title>
                                                         <path xmlns="http://www.w3.org/2000/svg" fill="#fff" d="m765.07,372.06h5.25v23.87h-5.25v-23.87Z" id="path1"/>
                                                         <path xmlns="http://www.w3.org/2000/svg" fill="#fff" d="m797.36,396.64l-2.63-2.35c-2.05,1.33-4.4,2.05-7.09,2.05-7.37,0-12.65-5.49-12.65-12.28v-.07c0-6.79,5.35-12.35,12.72-12.35s12.65,5.49,12.65,12.28v.07c0,2.56-.78,4.84-2.11,6.85l2.46,2.08-3.34,3.72Zm-6.55-5.8l-3.85-3.31,3.34-3.75,3.92,3.55c.44-.99.65-2.08.65-3.27v-.07c0-4.09-3-7.5-7.23-7.5s-7.16,3.34-7.16,7.43v.07c0,4.09,3,7.5,7.23,7.5,1.19,0,2.18-.24,3.1-.65Z" id="path2"/>
                                                         <path d="m427.28,362.8h7.66v18.13h20.81v-18.13h7.66v43.61h-7.66v-18.38h-20.81v18.38h-7.66v-43.61Z" id="path3"/>
                                                         <path d="m468.71,390.15v-.12c0-9.41,7.48-17.2,17.57-17.2s17.51,7.66,17.51,17.07v.12c0,9.35-7.48,17.13-17.63,17.13s-17.44-7.66-17.44-17.01Zm27.54,0v-.12c0-5.79-4.17-10.59-10.09-10.59s-9.91,4.73-9.91,10.47v.12c0,5.73,4.17,10.53,10.03,10.53s9.97-4.74,9.97-10.4Z" id="path4"/>
                                                         <path d="m506.76,402.11l3.36-5.11c3.61,2.68,7.41,4.05,10.78,4.05s5.11-1.37,5.11-3.55v-.12c0-2.55-3.49-3.43-7.35-4.61-4.86-1.37-10.28-3.36-10.28-9.66v-.12c0-6.23,5.17-10.03,11.71-10.03,4.11,0,8.6,1.43,12.09,3.74l-2.99,5.36c-3.18-1.93-6.54-3.12-9.28-3.12-2.93,0-4.61,1.37-4.61,3.24v.12c0,2.43,3.55,3.43,7.41,4.67,4.8,1.5,10.22,3.68,10.22,9.59v.12c0,6.92-5.36,10.34-12.21,10.34-4.67,0-9.84-1.68-13.96-4.92Z" id="path5"/>
                                                         <path d="m538.99,373.52h7.54v5.48c2.43-3.43,5.86-6.17,11.21-6.17,7.73,0,15.26,6.11,15.26,17.07v.12c0,10.9-7.48,17.07-15.26,17.07-5.48,0-8.91-2.74-11.21-5.79v15.08h-7.54v-42.86Zm26.35,16.51v-.12c0-6.35-4.3-10.53-9.41-10.53s-9.59,4.24-9.59,10.53v.12c0,6.29,4.49,10.53,9.59,10.53s9.41-4.05,9.41-10.53Z" id="path6"/>
                                                         <path d="m578.95,361.31h8.1v7.16h-8.1v-7.16Zm.31,12.21h7.54v32.9h-7.54v-32.9Z" id="path7"/>
                                                         <path d="m596.07,397.32v-17.32h-4.17v-6.48h4.17v-9.03h7.54v9.03h8.85v6.48h-8.85v16.14c0,2.93,1.5,4.11,4.05,4.11,1.68,0,3.18-.37,4.67-1.12v6.17c-1.87,1.06-3.92,1.68-6.79,1.68-5.54,0-9.47-2.43-9.47-9.66Z" id="path8"/>
                                                         <path d="m615.95,390.03v-.12c0-10.9,7.41-17.07,15.26-17.07,5.42,0,8.85,2.68,11.21,5.79v-5.11h7.54v32.9h-7.54v-5.48c-2.43,3.43-5.86,6.17-11.21,6.17-7.73,0-15.26-6.17-15.26-17.07Zm26.6,0v-.12c0-6.35-4.49-10.53-9.53-10.53s-9.47,3.99-9.47,10.53v.12c0,6.35,4.36,10.53,9.47,10.53s9.53-4.24,9.53-10.53Z" id="path9"/>
                                                         <path d="m658.35,360.93h7.54v45.48h-7.54v-45.48Z" id="path10"/>
                                                         <path d="m673.73,361.31h8.1v7.16h-8.1v-7.16Zm.31,12.21h7.54v32.9h-7.54v-32.9Z" id="path11"/>
                                                         <path d="m690.85,397.32v-17.32h-4.17v-6.48h4.17v-9.03h7.54v9.03h8.85v6.48h-8.85v16.14c0,2.93,1.5,4.11,4.05,4.11,1.68,0,3.18-.37,4.67-1.12v6.17c-1.87,1.06-3.92,1.68-6.79,1.68-5.54,0-9.47-2.43-9.47-9.66Z" id="path12"/>
                                                         <path d="m736.6,373.52h7.85l-13.08,33.83c-2.62,6.73-5.54,9.22-10.84,9.22-3.12,0-5.48-.75-7.79-1.99l2.55-5.61c1.37.75,2.87,1.25,4.42,1.25,2.12,0,3.43-.93,4.67-3.74l-13.71-32.96h8.1l9.28,24.42,8.54-24.42Z" id="path13"/>
                                                         <path d="m426.84,430.72h2.58v14.68h-2.58v-14.68Z" id="path14"/>
                                                         <path d="m433.01,434.32h2.54v1.7c.71-1.03,1.74-1.93,3.46-1.93,2.49,0,3.94,1.68,3.94,4.24v7.07h-2.54v-6.29c0-1.72-.86-2.7-2.37-2.7s-2.49,1.03-2.49,2.75v6.25h-2.54v-11.07Z" id="path15"/>
                                                         <path d="m446.36,442.33v-5.83h-1.4v-2.18h1.4v-3.04h2.54v3.04h2.98v2.18h-2.98v5.43c0,.99.5,1.38,1.36,1.38.57,0,1.07-.13,1.57-.38v2.08c-.63.36-1.32.57-2.29.57-1.87,0-3.19-.82-3.19-3.25Z" id="path16"/>
                                                         <path d="m453.47,439.9v-.04c0-3.17,2.24-5.77,5.41-5.77,3.52,0,5.3,2.77,5.3,5.96,0,.23-.02.46-.04.71h-8.14c.27,1.8,1.55,2.81,3.19,2.81,1.24,0,2.12-.46,3-1.32l1.49,1.32c-1.05,1.26-2.49,2.08-4.53,2.08-3.21,0-5.68-2.33-5.68-5.74Zm8.2-.82c-.17-1.64-1.13-2.92-2.81-2.92-1.55,0-2.64,1.2-2.87,2.92h5.68Z" id="path17"/>
                                                         <path d="m466.82,430.09h2.54v15.31h-2.54v-15.31Z" id="path18"/>
                                                         <path d="m472.63,430.09h2.54v15.31h-2.54v-15.31Z" id="path19"/>
                                                         <path d="m478.35,430.21h2.73v2.41h-2.73v-2.41Zm.1,4.11h2.54v11.07h-2.54v-11.07Z" id="path20"/>
                                                         <path d="m484.08,447.47l.94-1.91c1.2.73,2.43,1.17,3.94,1.17,2.26,0,3.5-1.17,3.5-3.4v-.86c-.92,1.19-2.08,2.03-3.9,2.03-2.6,0-5.03-1.93-5.03-5.18v-.04c0-3.27,2.45-5.2,5.03-5.2,1.87,0,3.02.86,3.88,1.89v-1.66h2.54v8.81c0,1.87-.48,3.25-1.41,4.17-1.01,1.01-2.56,1.49-4.55,1.49-1.82,0-3.52-.46-4.95-1.32Zm8.41-8.16v-.04c0-1.8-1.49-3.04-3.25-3.04s-3.15,1.22-3.15,3.04v.04c0,1.8,1.41,3.04,3.15,3.04s3.25-1.24,3.25-3.04Z" id="path21"/>
                                                         <path d="m497.55,439.9v-.04c0-3.17,2.24-5.77,5.41-5.77,3.52,0,5.3,2.77,5.3,5.96,0,.23-.02.46-.04.71h-8.14c.27,1.8,1.55,2.81,3.19,2.81,1.24,0,2.12-.46,3-1.32l1.49,1.32c-1.05,1.26-2.49,2.08-4.53,2.08-3.21,0-5.68-2.33-5.68-5.74Zm8.2-.82c-.17-1.64-1.13-2.92-2.81-2.92-1.55,0-2.64,1.2-2.87,2.92h5.68Z" id="path22"/>
                                                         <path d="m510.81,434.32h2.54v1.7c.71-1.03,1.74-1.93,3.46-1.93,2.49,0,3.94,1.68,3.94,4.24v7.07h-2.54v-6.29c0-1.72-.86-2.7-2.37-2.7s-2.49,1.03-2.49,2.75v6.25h-2.54v-11.07Z" id="path23"/>
                                                         <path d="m523.17,439.92v-.04c0-3.14,2.43-5.79,5.77-5.79,2.08,0,3.38.78,4.42,1.91l-1.59,1.7c-.78-.82-1.59-1.38-2.85-1.38-1.84,0-3.21,1.59-3.21,3.52v.04c0,1.97,1.36,3.54,3.31,3.54,1.2,0,2.08-.55,2.87-1.36l1.53,1.51c-1.09,1.22-2.37,2.08-4.53,2.08-3.29,0-5.72-2.58-5.72-5.72Z" id="path24"/>
                                                         <path d="m534.77,439.9v-.04c0-3.17,2.24-5.77,5.41-5.77,3.52,0,5.3,2.77,5.3,5.96,0,.23-.02.46-.04.71h-8.14c.27,1.8,1.55,2.81,3.19,2.81,1.24,0,2.12-.46,3-1.32l1.49,1.32c-1.05,1.26-2.49,2.08-4.53,2.08-3.21,0-5.68-2.33-5.68-5.74Zm8.2-.82c-.17-1.64-1.13-2.92-2.81-2.92-1.55,0-2.64,1.2-2.87,2.92h5.68Z" id="path25"/>
                                                         <path d="m556.88,443.68v1.72h-2.54v-15.31h2.54v6.08c.82-1.15,1.97-2.08,3.77-2.08,2.6,0,5.14,2.05,5.14,5.75v.04c0,3.67-2.52,5.75-5.14,5.75-1.85,0-3-.92-3.77-1.95Zm6.33-3.8v-.04c0-2.14-1.45-3.54-3.17-3.54s-3.23,1.43-3.23,3.54v.04c0,2.12,1.51,3.54,3.23,3.54s3.17-1.36,3.17-3.54Z" id="path26"/>
                                                         <path d="m567.77,439.9v-.04c0-3.17,2.24-5.77,5.41-5.77,3.52,0,5.3,2.77,5.3,5.96,0,.23-.02.46-.04.71h-8.14c.27,1.8,1.55,2.81,3.19,2.81,1.24,0,2.12-.46,3-1.32l1.49,1.32c-1.05,1.26-2.49,2.08-4.53,2.08-3.21,0-5.68-2.33-5.68-5.74Zm8.2-.82c-.17-1.64-1.13-2.92-2.81-2.92-1.55,0-2.64,1.2-2.87,2.92h5.68Z" id="path27"/>
                                                         <path d="m581.03,430.09h2.54v5.93c.71-1.03,1.74-1.93,3.46-1.93,2.49,0,3.94,1.68,3.94,4.24v7.07h-2.54v-6.29c0-1.72-.86-2.7-2.37-2.7s-2.49,1.03-2.49,2.75v6.25h-2.54v-15.31Z" id="path28"/>
                                                         <path d="m593.99,430.21h2.73v2.41h-2.73v-2.41Zm.1,4.11h2.54v11.07h-2.54v-11.07Z" id="path29"/>
                                                         <path d="m599.84,434.32h2.54v1.7c.71-1.03,1.74-1.93,3.46-1.93,2.49,0,3.94,1.68,3.94,4.24v7.07h-2.54v-6.29c0-1.72-.86-2.7-2.37-2.7s-2.49,1.03-2.49,2.75v6.25h-2.54v-11.07Z" id="path30"/>
                                                         <path d="m612.26,439.88v-.04c0-3.67,2.49-5.75,5.14-5.75,1.82,0,2.98.9,3.77,1.95v-5.96h2.54v15.31h-2.54v-1.85c-.82,1.15-1.97,2.08-3.77,2.08-2.6,0-5.14-2.08-5.14-5.75Zm8.95,0v-.04c0-2.14-1.51-3.54-3.21-3.54s-3.19,1.34-3.19,3.54v.04c0,2.14,1.47,3.54,3.19,3.54s3.21-1.43,3.21-3.54Z" id="path31"/>
                                                         <path d="m632.58,439.9v-.04c0-3.17,2.24-5.77,5.41-5.77,3.52,0,5.31,2.77,5.31,5.96,0,.23-.02.46-.04.71h-8.14c.27,1.8,1.55,2.81,3.19,2.81,1.24,0,2.12-.46,3-1.32l1.49,1.32c-1.05,1.26-2.49,2.08-4.53,2.08-3.21,0-5.68-2.33-5.68-5.74Zm8.2-.82c-.17-1.64-1.13-2.92-2.81-2.92-1.55,0-2.64,1.2-2.87,2.92h5.68Z" id="path32"/>
                                                         <path d="m644.2,434.32h2.73l2.98,8.13,3-8.13h2.66l-4.53,11.15h-2.29l-4.55-11.15Z" id="path33"/>
                                                         <path d="m656.47,439.9v-.04c0-3.17,2.24-5.77,5.41-5.77,3.52,0,5.3,2.77,5.3,5.96,0,.23-.02.46-.04.71h-8.14c.27,1.8,1.55,2.81,3.19,2.81,1.24,0,2.12-.46,3-1.32l1.49,1.32c-1.05,1.26-2.49,2.08-4.53,2.08-3.21,0-5.68-2.33-5.68-5.74Zm8.2-.82c-.17-1.64-1.13-2.92-2.81-2.92-1.55,0-2.64,1.2-2.87,2.92h5.68Z" id="path34"/>
                                                         <path d="m669.73,434.32h2.54v2.49c.69-1.66,1.97-2.79,3.92-2.71v2.68h-.15c-2.22,0-3.77,1.45-3.77,4.38v4.21h-2.54v-11.07Z" id="path35"/>
                                                         <path d="m686.4,434.32h2.64l-4.4,11.39c-.88,2.26-1.87,3.1-3.65,3.1-1.05,0-1.85-.25-2.62-.67l.86-1.89c.46.25.96.42,1.49.42.71,0,1.15-.31,1.57-1.26l-4.61-11.09h2.73l3.12,8.22,2.87-8.22Z" id="path36"/>
                                                         <path d="m696.91,439.9v-.04c0-3.17,2.24-5.77,5.41-5.77,3.52,0,5.31,2.77,5.31,5.96,0,.23-.02.46-.04.71h-8.14c.27,1.8,1.55,2.81,3.19,2.81,1.24,0,2.12-.46,3-1.32l1.49,1.32c-1.05,1.26-2.49,2.08-4.53,2.08-3.21,0-5.68-2.33-5.68-5.74Zm8.2-.82c-.17-1.64-1.13-2.92-2.81-2.92-1.55,0-2.64,1.2-2.87,2.92h5.68Z" id="path37"/>
                                                         <path d="m714.1,438.03l2.56-3.71h2.7l-3.9,5.41,4.07,5.66h-2.77l-2.73-3.94-2.7,3.94h-2.7l4.05-5.64-3.9-5.43h2.77l2.56,3.71Z" id="path38"/>
                                                         <path d="m721.55,434.32h2.54v1.84c.82-1.15,1.97-2.08,3.77-2.08,2.6,0,5.14,2.05,5.14,5.75v.04c0,3.67-2.52,5.75-5.14,5.75-1.85,0-3-.92-3.77-1.95v5.07h-2.54v-14.43Zm8.87,5.56v-.04c0-2.14-1.45-3.54-3.17-3.54s-3.23,1.43-3.23,3.54v.04c0,2.12,1.51,3.54,3.23,3.54s3.17-1.36,3.17-3.54Z" id="path39"/>
                                                         <path d="m734.99,439.9v-.04c0-3.17,2.24-5.77,5.41-5.77,3.52,0,5.31,2.77,5.31,5.96,0,.23-.02.46-.04.71h-8.14c.27,1.8,1.55,2.81,3.19,2.81,1.24,0,2.12-.46,3-1.32l1.49,1.32c-1.05,1.26-2.49,2.08-4.53,2.08-3.21,0-5.68-2.33-5.68-5.74Zm8.2-.82c-.17-1.64-1.13-2.92-2.81-2.92-1.55,0-2.64,1.2-2.87,2.92h5.68Z" id="path40"/>
                                                         <path d="m748.25,434.32h2.54v2.49c.69-1.66,1.97-2.79,3.92-2.71v2.68h-.15c-2.22,0-3.77,1.45-3.77,4.38v4.21h-2.54v-11.07Z" id="path41"/>
                                                         <path d="m756.68,430.21h2.73v2.41h-2.73v-2.41Zm.1,4.11h2.54v11.07h-2.54v-11.07Z" id="path42"/>
                                                         <path d="m761.88,439.9v-.04c0-3.17,2.24-5.77,5.41-5.77,3.52,0,5.31,2.77,5.31,5.96,0,.23-.02.46-.04.71h-8.14c.27,1.8,1.55,2.81,3.19,2.81,1.24,0,2.12-.46,3-1.32l1.49,1.32c-1.05,1.26-2.49,2.08-4.53,2.08-3.21,0-5.68-2.33-5.68-5.74Zm8.2-.82c-.17-1.64-1.13-2.92-2.81-2.92-1.55,0-2.64,1.2-2.87,2.92h5.68Z" id="path43"/>
                                                         <path d="m775.14,434.32h2.54v1.7c.71-1.03,1.74-1.93,3.46-1.93,2.49,0,3.94,1.68,3.94,4.24v7.07h-2.54v-6.29c0-1.72-.86-2.7-2.37-2.7s-2.49,1.03-2.49,2.75v6.25h-2.54v-11.07Z" id="path44"/>
                                                         <path d="m787.5,439.92v-.04c0-3.14,2.43-5.79,5.77-5.79,2.08,0,3.38.78,4.42,1.91l-1.59,1.7c-.78-.82-1.59-1.38-2.85-1.38-1.85,0-3.21,1.59-3.21,3.52v.04c0,1.97,1.36,3.54,3.31,3.54,1.2,0,2.08-.55,2.87-1.36l1.53,1.51c-1.09,1.22-2.37,2.08-4.53,2.08-3.29,0-5.72-2.58-5.72-5.72Z" id="path45"/>
                                                         <path d="m799.11,439.9v-.04c0-3.17,2.24-5.77,5.41-5.77,3.52,0,5.3,2.77,5.3,5.96,0,.23-.02.46-.04.71h-8.14c.27,1.8,1.55,2.81,3.19,2.81,1.24,0,2.12-.46,3-1.32l1.49,1.32c-1.05,1.26-2.49,2.08-4.53,2.08-3.21,0-5.68-2.33-5.68-5.74Zm8.2-.82c-.17-1.64-1.13-2.92-2.81-2.92-1.55,0-2.64,1.2-2.87,2.92h5.68Z" id="path46"/>
                                          </svg>
                                          );

export {
  AramarkHospitalityIq,
  AramarkHospitalityIqBlackAndWhite
}