export default <svg
width={44}
height={25}
viewBox="0 0 44 25"
fill="none"
xmlns="http://www.w3.org/2000/svg"

>
<path
  d="M12.1238 4.03C12.1238 5.73554 10.7956 7.06 9.22765 7.06C7.65975 7.06 6.33154 5.73554 6.33154 4.03C6.33154 2.32446 7.65975 1 9.22765 1C10.7956 1 12.1238 2.32446 12.1238 4.03Z"
  stroke="black"
  strokeWidth={2}
/>
<path
  d="M41.5861 5.16667C42.1383 5.16667 42.5861 4.71896 42.5861 4.16667C42.5861 3.61439 42.1383 3.16667 41.5861 3.16667L41.5861 5.16667ZM12.3813 5.16667L41.5861 5.16667L41.5861 3.16667L12.3813 3.16667L12.3813 5.16667Z"
  fill="black"
/>
<path
  d="M1.30371 4.16667L6.33901 4.16667"
  stroke="black"
  strokeWidth={2}
  strokeLinecap="round"
/>
<path
  d="M28.7512 12.6367C28.7512 10.9311 30.0794 9.60668 31.6473 9.60668C33.2152 9.60668 34.5435 10.9311 34.5435 12.6367C34.5435 14.3422 33.2152 15.6667 31.6473 15.6667C30.0794 15.6667 28.7512 14.3422 28.7512 12.6367Z"
  stroke="black"
  strokeWidth={2}
/>
<path
  d="M1.30355 11.4998C0.751268 11.4998 0.303553 11.9475 0.303553 12.4998C0.303553 13.0521 0.751268 13.4998 1.30355 13.4998V11.4998ZM28.4941 11.4998H1.30355V13.4998H28.4941V11.4998Z"
  fill="black"
/>
<path
  d="M41.5859 12.4998L34.5365 12.4998"
  stroke="black"
  strokeWidth={2}
  strokeLinecap="round"
/>
<path
  d="M17.1594 20.6967C17.1594 22.4022 15.8312 23.7267 14.2633 23.7267C12.6954 23.7267 11.3672 22.4022 11.3672 20.6967C11.3672 18.9911 12.6954 17.6667 14.2633 17.6667C15.8312 17.6667 17.1594 18.9911 17.1594 20.6967Z"
  stroke="black"
  strokeWidth={2}
/>
<path
  d="M42.0892 21.8333C42.6415 21.8333 43.0892 21.3856 43.0892 20.8333C43.0892 20.2811 42.6415 19.8333 42.0892 19.8333L42.0892 21.8333ZM17 21.8333L42.0892 21.8333L42.0892 19.8333L17 19.8333L17 21.8333Z"
  fill="black"
/>
<path
  d="M1.30371 20.8333L11.3743 20.8333"
  stroke="black"
  strokeWidth={2}
  strokeLinecap="round"
/>
</svg>