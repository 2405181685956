import styles from './PopupModal.module.scss';
import Modal from 'react-modal';
import * as Framework from 'components/framework'

const PopupModal = ({ handleClose, popupData, show, }) => {

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '80%',
      maxHeight: '80%',
      width: 'auto',
      height: 'auto',
      zIndex: '99999999',
      borderRadius: '30px',
      border: 'none',
      boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 30px 0px",
      overflow: 'hidden'
    },
  };

  const cmpTheme = {
    btn: {
      style: 'secondary',
      type: 'button'
    },
    msgDesc: {
      color: 'text-color--black',
      font: 'font--gotham',
      size: 'headline-text-size--medium'
    },
    links: {
      primary: {
        style: 'primaryRed',
        type: 'button'
      },
      secondary: {
        style: 'outlineDark',
        type: 'button'
      }
    },
  }

  return (
    <Modal
      isOpen={show}
      // onAfterOpen={afterOpenModal}
      onRequestClose={handleClose}
      style={customStyles}
      contentLabel="Example Modal"
      shouldCloseOnOverlayClick={false}
    >

      <div className={styles.model}>

        <div className={styles.msgDesc}>
          <div>
            <span className={cmpTheme.msgDesc.color + ' ' + cmpTheme.msgDesc.font + ' ' + cmpTheme.msgDesc.size}
              dangerouslySetInnerHTML={{
                __html: popupData?.message
              }}>
            </span>
          </div>
        </div>

        <div className={styles.links}>
          {popupData?.link1 && <Framework.Anchor
            endIcon={popupData?.link1?.endIcon}
            href={popupData?.link1?.href}
            label={popupData?.link1?.label}
            startIcon={popupData?.link1?.startIcon}
            target={popupData?.link1?.target}
            theme={cmpTheme?.links?.primary}
            popupModal={popupData?.link1?.popupModal}
            addtOnClick={() => handleClose()}
          />}
          {popupData?.link2 && <Framework.Anchor
            endIcon={popupData?.link2.endIcon}
            href={popupData?.link2?.href}
            label={popupData?.link2?.label}
            startIcon={popupData?.link2?.startIcon}
            target={popupData?.link2?.target}
            theme={cmpTheme?.links?.primary}
            popupModal={popupData?.link2?.popupModal}
            addtOnClick={() => handleClose()}
          />}
        </div>
      </div>
    </Modal>
  );
};

export default PopupModal;