import React from 'react'
import { Constants, ModelManager, PathUtils } from '@adobe/aem-spa-page-model-manager'
import { createBrowserHistory } from 'history'
import ReactDOM from 'react-dom'
import { BrowserRouter, Router } from 'react-router-dom'
import PageHelper from 'components/scaffolding/PageHelper'
import App from './App'
import 'components/import-components'
import 'styles/main.scss'

const render = (pageModel) => {
  const history = createBrowserHistory()
  ReactDOM.render(
    <Router history={history}>
      <BrowserRouter forceRefresh={true}>
        <PageHelper />
        <App
          history={history}
          cqChildren={pageModel[Constants.CHILDREN_PROP]}
          cqItems={pageModel[Constants.ITEMS_PROP]}
          cqItemsOrder={pageModel[Constants.ITEMS_ORDER_PROP]}
          cqPath={pageModel[Constants.PATH_PROP]}
          locationPathname={window.location.pathname}
          {...pageModel}
        />
      </BrowserRouter>
    </Router>,
    document.getElementById('aramark')
  )
}

//get the errorPageRoot folder
const errorPageRoot = PathUtils.getMetaPropertyValue('cq:errorpages') + '/';

ModelManager.initialize({ path: process.env.REACT_APP_PAGE_MODEL_PATH, errorPageRoot }).then(
  render
)
