import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import styles from './Form.module.scss'
import { useForm } from 'react-hook-form'
import * as Framework from 'components/framework'
import { validation } from './Element/validation'
import ReCAPTCHA from 'react-google-recaptcha'
import { trackProcessEvent } from 'utils/analytics'
import * as TranslationData from 'components/content/Form/TranslationFiles'

/**
 * The Form Component is used to display a series of related form fields
 */
const Form = ({
  addtClassName,
  analytics,
  buttons,
  fields,
  formError,
  id,
  layout,
  onSubmit,
  captcha,
  captchaSiteKey,
  onChangeField,
  formServicesAreas,
  locale,
  resetFilterDataFeed,
  onFormReset,
  ...props
}) => {
  const [formFields, setFormFields] = useState({})
  const { register, reset, resetField, formState: { isDirty, isValid, isSubmitted, errors }, handleSubmit } = useForm({ mode: 'all' })
  const [formErrorState, setFormErrorState] = useState(formError)
  const [captchaStatus, setCaptchaSatus] = useState(false)
  const recaptchaRef = React.useRef();

  const localeValue = locale;
  const cmpTheme = {
    error: {
      color: 'text-color--red',
      font: 'font--gotham-book',
      size: 'text-size--large'
    }
  }

  // Generate error messages
  // TODO: Move this to i18n dictionary
  const getError = useCallback((field, label) => {
    const getIndex = fields?.findIndex(p => p?.params?.name == "ContractInvoiceReservation");
    if (getIndex !== -1) {
      if (!fields[getIndex]?.params?.required) {
        delete errors?.ContractInvoiceReservation;
      }
    }

    const updateLocalErrorMessage = (errorMessage) => {

      const translated = TranslationData.default;
      if (localeValue && translated?.form[localeValue]) {
        const enValues = translated?.form[localeValue][0];

        if (enValues[errorMessage]) {

          return enValues[errorMessage];
        }
      }
      return errorMessage;
    }

    if (errors[field]) {
      switch (errors[field].type) {
        case 'required':
          return `${label}` + ' ' + updateLocalErrorMessage('is required');
        case 'min':
          return `${label}` + ' ' + updateLocalErrorMessage('must be more than') + ' ' + `${fields[field].min}` + '.';
        case 'max':
          return `${label}` + ' ' + updateLocalErrorMessage('must be shorter than') + ' ' + `${fields[field].max}` + '.';
        case 'minLength':
          return `${label}` + ' ' + updateLocalErrorMessage('must have at least') + ' ' + `${fields[field].minLength}` + ' ' + updateLocalErrorMessage('characters') + '.';
        case 'maxLength':
          return `${label}` + ' ' + updateLocalErrorMessage('can only have') + ' ' + `${fields[field].maxLength}` + ' ' + updateLocalErrorMessage('characters') + '.';
        case 'pattern':
          return `${label}` + ' ' + updateLocalErrorMessage('is in an incorrect format') + '.';
        case 'validate':
          return `${label}` + ' ' + updateLocalErrorMessage('cannot be validated') + '.';
        default:
          return `${label}` + ' ' + updateLocalErrorMessage('is invalid') + '.';
      }
    }
    return null
  }, [errors, fields])

  // Handle submit tracking
  const trackStart = useCallback(() => {
    trackProcessEvent({
      action: 'start',
      type: 'process',
      context: analytics?.context
    })
  }, [analytics])

  // Handle submit tracking
  const trackSubmit = useCallback(() => {
    trackProcessEvent({
      action: 'complete',
      type: 'process',
      context: analytics?.context
    })
  }, [analytics])

  // Handle server error tracking
  const trackServerError = useCallback(() => {
    trackProcessEvent({
      type: 'system-error',
      context: analytics?.context,
      value: formError
    })
  }, [analytics?.context, formError])

  const updateLocalErrorMessage = (errorMessage) => {

    const translated = TranslationData.default;
    if (localeValue && translated?.form[localeValue]) {
      const enValues = translated?.form[localeValue][0];

      if (enValues[errorMessage]) {

        return enValues[errorMessage];
      }
    }
    return errorMessage;
  }

  // Handle validation error tracking
  const trackValidationErrors = useCallback(() => {
    trackProcessEvent({
      type: 'validation-error',
      context: analytics?.context,
      value: Object.keys(errors).map((key) =>
        getError(key, formFields[key].label)
      ).join()
    })
  }, [analytics?.context, errors, formFields, getError])

  // Create a list of form fields for error handling
  useEffect(() => {
    const elementsArray = fields?.map((field) => {
      return {
        [field?.params?.name]: {
          name: field?.params?.name,
          label: field?.params?.label,
          min: field?.params?.min,
          max: field?.params?.max,
          minLength: field?.params?.minLength,
          maxLength: field?.params?.maxLength
        }
      }
    })
    setFormFields(elementsArray?.reduce((acc, curr) => ({ ...acc, ...curr }), {}) || {})
  }, [fields])

  // Trigger analytics flow on server error
  useEffect(() => {
    setFormErrorState(formError)
    if (formError) {
      trackServerError()
    }
  }, [formError, trackServerError])

  // Trigger analytics flow on validation error
  useEffect(() => {
    if (!!Object.keys(errors).length) {
      trackValidationErrors()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Object.keys(errors).length, trackValidationErrors])

  // Trigger analytics flow on form activity
  useEffect(() => {
    if (isDirty) {
      trackStart()
    }
  }, [isDirty])

  const getPagePath=()=>{
    const pageUrl=window?.location?.href;
    var url = pageUrl?.split( '/' );
    const pagePath=url[url?.length - 2] + '/' + url[url?.length - 1];
    return pagePath;
  }

  // Handle form submission
  const onFormSubmit = useCallback((data) => {
    const token = recaptchaRef?.current?.getValue();
    data['Lead_Webform__c']=getPagePath();
    onSubmit(data,token)
    trackSubmit()
  }, [onSubmit, trackSubmit])

  // Handle form reset
  const onReset = () => {
    setFormErrorState(null)
    reset({})
    onFormReset();
  }

  const onChangeFieldVal = (e) => {
    onChangeField(e)
    if (e?.target?.name === 'country') {
      resetField('socialSecurityNumber')
    }
  }
// feed component v2 use only 
  useEffect(() => {
    if (resetFilterDataFeed) {
      resetField('searchFilter')
    }
  }, [resetFilterDataFeed])
// feed component v2 use only 
  return (
    <form
      onSubmit={handleSubmit(onFormSubmit)}
      onReset={onReset}
      onChange={analytics?.context === 'my_pay_form' ? onChangeFieldVal : onChangeField}
      className={addtClassName ? styles.form + ' ' + addtClassName : styles.form}
      data-layout={layout}
      noValidate // TODO: Should we skip browser validation?
      {...props}
    >
      <div className={styles[layout]} id={`${id}-layout`}>
        {/* Render remote server error */}
        {formErrorState && (
          <div className={styles['server-error']}>
            <Framework.Anchor
              href={`#${id}-buttons`}
              inline
              label={formErrorState}
              target='_self'
              theme={{
                style: 'textError',
                type: 'link'
              }}
            />
          </div>
        )}
        {/* If the errors object isn't empty and the layout is not simple, render errors */}
        {!!Object.keys(errors).length && layout === 'list' && (
          <div className={styles['errors-container']} id={`${id}-errors`}>
            <Framework.Text
              content={updateLocalErrorMessage('Please correct the following errors:')}
              theme={cmpTheme?.error}
            />
            {/* Frontend validation errors */}
            <ul className={styles['errors-list']}>
              {Object.keys(errors).map((key, index) => (
                <li key={index} className={styles.error}>
                  <Framework.Anchor
                    href={`#${id}-${key}`}
                    inline
                    label={getError(key, formFields[key].label)}
                    target='_self'
                    theme={{
                      style: 'textError',
                      type: 'link'
                    }}
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
        {/* Go through the fields list and render the Form Elements */}
        <div className={styles['form-elements']} id={`${id}-elements`}>
          {fields?.map((field, index) =>
            <Framework.FormElement
              id={`${id}-${field?.params?.name}`}
              key={index}
              type={field?.type}
              error={getError(field?.params?.name, field?.params?.label)}
              isSubmitted={isSubmitted}
              isValid={isSubmitted && !errors[field?.params?.name]}
              {...field?.params}
              {...register(field?.params?.name, {
                required: field?.params?.required,
                min: field?.params?.min,
                max: field?.params?.max,
                minLength: field?.params?.minLength,
                maxLength: field?.params?.maxLength,
                pattern: validation[field?.type],
                validate: field?.params?.validate,
              })}
              formServicesAreas={formServicesAreas}
            />
          )}
        </div>
        {/* Enable/Disable reCaptcha */}
        {captcha && (
          <div className={styles.captcha}>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={captchaSiteKey} // Google's dev public site key
              // sitekey={'6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'} // Google's dev public site testing key in local host 
              onChange={setCaptchaSatus}
            />
          </div>
        )}
        {/* Go through the buttons list and render the Form buttons */}
        <div className={styles['form-buttons']} id={`${id}-buttons`}>
          {buttons?.map((field, index) =>
            <Framework.FormElement
              id={`${id}-${field?.type}-button`}
              key={index}
              type={field?.type}
              analytics={analytics}
              disabled={field?.type === 'submit'
                ? !isValid || (captcha && !captchaStatus)
                : null
              }
              {...field?.params}
            />
          )}
        </div>
      </div>
    </form>
  )
}

Form.propTypes = {
  /**
   * Additional Class Name - passed from the parent Component
   * -- allows box model adjustments (margin, padding, position styles etc)
   * -- should not be used for typography or color adjustments (font family, font weight, text color etc)
   */
  addtClassName: PropTypes.string,
  /**
   * Analytics payload for tracking interaction events
   */
  analytics: PropTypes.shape({
    component: PropTypes.string,
    context: PropTypes.string
  }),
  /**
   * Buttons - passed from template
   */
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      params: PropTypes.object
    }),
  ),
  /**
   * Enable/disable captcha
   */
  captcha: PropTypes.bool,
  /**
   * Aramark Captcha public key
   */
  captchaSiteKey: PropTypes.string,
  /**
   * Fields - passed from template
   */
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      params: PropTypes.object
    }),
  ).isRequired,
  /**
   * Remote form validation error
   */
  formError: PropTypes.string,
  /**
   * Unique identifier for the form
   */
  id: PropTypes.string,
  /**
   * Layout
   */
  layout: PropTypes.oneOf(['custom', 'list', 'simple']).isRequired,
  /**
   * Form submit handler
   */
  onSubmit: PropTypes.func.isRequired,
  /**
   * Enable/disable reCaptcha
   */
  recaptcha: PropTypes.bool
}

Form.defaultProps = {
  layout: 'list',
}

export default Form
