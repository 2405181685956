export default <svg
width={26}
height={25}
viewBox="0 0 26 25"
fill="none"
xmlns="http://www.w3.org/2000/svg"

>
<path
  d="M1.64697 1L24.8093 24"
  stroke="black"
  strokeWidth={2}
  strokeLinecap="round"
/>
<path
  d="M24.8096 1L1.64723 24"
  stroke="black"
  strokeWidth={2}
  strokeLinecap="round"
/>
</svg>