const AramarkSeniorLifeLogo = ({ logoAltText }) => (
<svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 834.23 229.21"
> 
    <defs>
        <title>{ logoAltText }</title>
        <style>{".cls-1{fill:#fff;}.cls-2{fill:#e7212e;}"}</style>
    </defs>
    <path
        className="cls-1"
        d="m24.43,204.01c9.83,2.14,14.36,5.73,14.36,12.54,0,7.62-6.3,12.54-15.06,12.54-6.99,0-12.73-2.33-18.02-7.06l3.09-3.65c4.6,4.16,9.01,6.24,15.12,6.24s9.83-3.09,9.83-7.56c0-4.16-2.21-6.49-11.47-8.44-10.14-2.21-14.81-5.48-14.81-12.79s6.11-12.1,14.49-12.1c6.43,0,11.03,1.83,15.5,5.42l-2.9,3.84c-4.1-3.34-8.19-4.79-12.73-4.79-5.73,0-9.39,3.15-9.39,7.18s2.27,6.55,11.97,8.63Z"
    />
    <path
        className="cls-1"
        d="m60.99,224.99c4.47,0,7.62-1.83,10.27-4.6l3.02,2.71c-3.28,3.65-7.25,6.11-13.42,6.11-8.95,0-16.25-6.87-16.25-17.01,0-9.45,6.62-17.01,15.62-17.01,9.64,0,15.18,7.69,15.18,17.26,0,.44,0,.95-.06,1.51h-25.83c.69,7.06,5.73,11.03,11.47,11.03Zm9.51-14.62c-.5-5.92-3.91-11.09-10.4-11.09-5.67,0-9.95,4.73-10.58,11.09h20.98Z"
    />
    <path
        className="cls-1"
        d="m88.28,228.46h-4.85v-32.57h4.85v5.67c2.14-3.53,5.54-6.36,11.15-6.36,7.88,0,12.47,5.29,12.47,13.04v20.22h-4.85v-19.03c0-6.05-3.28-9.83-9.01-9.83s-9.77,4.09-9.77,10.21v18.65Z"
    />
    <path
        className="cls-1"
        d="m121.37,188.76v-5.36h5.54v5.36h-5.54Zm.31,39.69v-32.57h4.85v32.57h-4.85Z"
    />
    <path
        className="cls-1"
        d="m151.37,229.21c-9.77,0-16.88-7.69-16.88-16.95s7.18-17.07,17.01-17.07,16.95,7.69,16.95,16.95-7.25,17.07-17.07,17.07Zm0-29.74c-7.06,0-11.91,5.61-11.91,12.66s5.17,12.73,12.03,12.73,11.97-5.61,11.97-12.6-5.23-12.79-12.1-12.79Z"
    />
    <path
        className="cls-1"
        d="m180.87,228.46h-4.85v-32.57h4.85v8.51c2.39-5.42,7.12-9.33,13.29-9.07v5.23h-.38c-7.12,0-12.92,5.1-12.92,14.93v12.98Z"
    />
    <path
        className="cls-1"
        d="m202.36,184.35h4.98v39.5h24.82v4.6h-29.8v-44.1Z"
    />
    <path className="cls-1" d="m247.66,184.35v44.1h-4.98v-44.1h4.98Z" />
    <path
        className="cls-1"
        d="m289.89,188.95h-26.71v15.75h23.88v4.54h-23.88v19.22h-4.98v-44.1h31.69v4.6Z"
    />
    <path
        className="cls-1"
        d="m329.41,188.89h-26.9v15.06h24.07v4.54h-24.07v15.44h27.22v4.54h-32.19v-44.1h31.88v4.54Z"
    />
    <path
        className="cls-1"
        d="m348.36,191.03h4.66v12.98h13.1v4.54h-13.1v12.98h-4.66v-12.98h-13.1v-4.54h13.1v-12.98Z"
    />
    <path
        className="cls-2"
        d="m740.05,49.38c7.71,0,15.09-3.7,19.75-9.89,3.23-4.31,4.94-9.42,4.94-14.8s-1.71-10.5-4.94-14.81c-4.66-6.19-12.04-9.88-19.75-9.88-13.62,0-24.7,11.07-24.7,24.69s11.08,24.69,24.7,24.69Z"
    />
    <path
        className="cls-2"
        d="m834.05,50.86c-.27-.33-.82-.36-1.46-.16-.12.04-.23.09-.35.13-18.53,6.8-59.36,14.2-114.33,4.43-65.46-11.63-88.96-5.78-100.51-1.56-.1.04-.2.07-.29.11-.7.29-1.13.74-1.1,1.21.03.48.54.87,1.28,1.07.04,0,.07,0,.1.02,82.65,18.56,31.49,87.66-81.34,152.84-.2.1-.4.2-.56.32-.64.44-.98.99-.85,1.45.16.53.91.8,1.87.75.09,0,.18-.01.25-.02,19.68-1.77,48.67-12.3,83.48-27.79.01,0,.02-.01.04-.02,4.06-1.8,8.2-3.68,12.42-5.63,17.59-7.7,37.56-14.91,50.24-10.92,16.71,5.26,19.93,17.71,21.93,41.31.01.12.03.24.04.37.08.61.32,1.06.72,1.18.43.13.93-.17,1.37-.72.05-.07.1-.14.16-.21,8.12-11.17,38.25-54.82,39.96-89.28,40.48-23.47,74.26-47.56,86.47-66.81.16-.2.29-.42.39-.63.3-.58.35-1.13.08-1.46Z"
    />
    <path
        className="cls-1"
        d="m439.38,63.44v7.09c-4.78-3.41-10.28-5.86-16.24-7.09-2.84-.58-5.77-.89-8.79-.89s-5.95.31-8.79.89c-19.56,4.04-34.28,21.31-34.4,42.04,0,.08,0,.17,0,.26s0,.18,0,.26c.14,23.73,19.42,42.93,43.19,42.93,9.33,0,17.97-2.95,25.03-7.98v7.08h18.17V63.44h-18.17Zm-25.03,67.33c-13.82,0-25.02-11.21-25.02-25.03s11.2-25.03,25.02-25.03,25.02,11.21,25.02,25.03-11.2,25.03-25.02,25.03Z"
    />
    <path
        className="cls-1"
        d="m212.42,63.44v7.09c-4.78-3.41-10.29-5.86-16.24-7.09-2.83-.58-5.78-.89-8.79-.89s-5.95.31-8.78.89c-11.43,2.37-21.2,9.24-27.38,18.68-4.37,6.68-6.94,14.63-7.02,23.19,0,.15,0,.29,0,.44s0,.3,0,.44c.09,8.55,2.66,16.52,7.02,23.19,7.71,11.79,21.03,19.57,36.16,19.57,9.34,0,17.97-2.95,25.03-7.98v7.08h18.17V63.44h-18.17Zm-25.03,67.33c-13.82,0-25.02-11.21-25.02-25.03s11.21-25.03,25.02-25.03,25.03,11.21,25.03,25.03-11.21,25.03-25.03,25.03Z"
    />
    <path
        className="cls-1"
        d="m68.22,63.44v7.09c-4.78-3.41-10.28-5.86-16.24-7.09-2.84-.58-5.78-.89-8.78-.89s-5.95.31-8.79.89C14.76,67.51,0,84.9,0,105.74c0,23.86,19.34,43.19,43.2,43.19,9.33,0,17.97-2.95,25.02-7.98v7.08h18.17V63.44h-18.17Zm-25.02,67.33c-13.83,0-25.03-11.21-25.03-25.03s11.2-25.03,25.03-25.03,25.02,11.21,25.02,25.03-11.21,25.03-25.02,25.03Z"
    />
    <path
        className="cls-1"
        d="m501.33,63.44c-4.92,1.25-9.34,3.73-12.91,7.1v-7.1h-18.18v84.6h18.18v-47.19h0c0-11.12,9.01-20.12,20.13-20.12h5.9v-18.17h-5.9c-2.5,0-4.91.31-7.21.89Z"
    />
    <path
        className="cls-1"
        d="m336.46,63.44c-2.5-.58-5.11-.89-7.79-.89-.25,0-.5,0-.75,0-.2,0-.4.02-.6.02h-.1c-9.49.42-16.64,4.88-20.49,7.96-2.08,1.66-3.19,2.92-3.19,2.92,0,0-1.51-1.68-4.16-3.72-3.67-2.83-9.51-6.34-16.51-7.06-.06,0-.12,0-.18-.01-.13-.01-.27-.03-.41-.03-.11,0-.2-.01-.3-.03-.11,0-.22-.01-.33-.02-.14,0-.28-.01-.41-.01-.08,0-.15-.01-.24-.01-.22,0-.44,0-.66,0-2.53,0-4.94.3-7.22.89-4.33,1.15-8.22,3.4-11.66,6.85v-6.85h-18.16v84.6h18.16v-50.2c0-9.45,7.22-17.12,16.94-17.12s16.06,7.67,16.06,17.12v50.2h18.16v-50.2c0-9.45,7.03-17.12,16.49-17.12s15.61,7.67,15.61,17.12v50.2h18.17v-51.27c0-16.19-11.31-29.79-26.43-33.32Z"
    />
    <polygon
        className="cls-1"
        points="592.84 63.44 570.94 63.44 541.54 99.03 541.54 42.82 541.54 35.25 523.36 35.25 523.36 148.04 527.42 148.04 541.54 148.04 541.54 112.4 570.94 148.04 592.84 148.04 557.92 105.74 592.84 63.44"
    />
    <path
        className="cls-1"
        d="m130.17,63.44c-4.91,1.25-9.33,3.73-12.91,7.1v-7.1h-18.17v84.6h18.17v-45.53c0-13.02,8.47-21.78,21.47-21.78h4.55v-18.17h-5.9c-2.48,0-4.9.31-7.21.89Z"
    />
    <path
        className="cls-2"
        d="m738.63,191.2l1.43,2.1h-1.51l-1.25-1.89h-1.02v1.89h-1.3v-5.87h2.61c1.4,0,2.3.72,2.3,1.94,0,.96-.52,1.55-1.26,1.83Zm-1.08-2.62h-1.26v1.72h1.3c.66,0,1-.35,1-.85,0-.58-.4-.87-1.04-.87Z"
    />
    <path
        className="cls-2"
        d="m737.24,196.33c-3.23,0-5.85-2.62-5.85-5.85s2.62-5.85,5.85-5.85,5.85,2.62,5.85,5.85-2.62,5.85-5.85,5.85Zm0-10.5c-2.56,0-4.65,2.09-4.65,4.65s2.09,4.65,4.65,4.65,4.65-2.09,4.65-4.65-2.09-4.65-4.65-4.65Z"
    />
</svg>
);

export { AramarkSeniorLifeLogo };
