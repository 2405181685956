import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styles from './Button.module.scss'
import Typography from 'components/scaffolding/Typography'
import { trackInteraction } from 'utils/analytics'

/**
 * The Form Button Component is used within a Form to render a submit or reset button
 */
const Button = ({
  addtClassName,
  analytics,
  disabled,
  label,
  onClick,
  theme,
  type,
  ...props
}) => {
  
  const buttonTheme = {
    primary: {
      color: 'text-color--inherit',
      font: 'font--gotham-medium',
      size: 'text-size--cta'
    },
    secondary: {
      color: 'text-color--inherit',
      font: 'font--gotham-medium',
      size: 'text-size--cta'
    }
  }

  // Handle button tracking
  const trackClick = useCallback(() => {
    trackInteraction({
      action: analytics?.action,
      component: analytics?.component,
      context: analytics?.context,
      label: analytics?.deselector?analytics?.eventLabel:label
    })
  }, [analytics, label])

  // Handle button click
  const handleClick = useCallback(() => {
    trackClick()
    onClick()
  }, [onClick, trackClick])

  return (
    <button
      aria-disabled={disabled}
      aria-label={label}
      className={
        styles[theme?.style] + 
        (addtClassName ? ' ' + addtClassName : '')
      }
      disabled={disabled}
      onClick={onClick ? handleClick : trackClick}
      type={type}
      {...props}
    >
      {(() => {
        switch (theme?.type) {
          case 'wrapper':
            return label
          case 'button':
            return (
              <Typography
                color={buttonTheme[theme?.style]?.color}
                font={buttonTheme[theme?.style]?.font}
                size={buttonTheme[theme?.style]?.size}
              >
                {label}
              </Typography>
            )
          default:
        }
      })()}
    </button>
  )
}

Button.propTypes = {
  /**
   * Additional Class Name - passed from the parent Component
   * -- allows box model adjustments (margin, padding, position styles etc)
   * -- should not be used for typography or color adjustments (font family, font weight, text color etc)
   */
  addtClassName: PropTypes.string,
  /**
   * Analytics payload for tracking interaction events
   */
  analytics: PropTypes.shape({
    component: PropTypes.string,
    context: PropTypes.string
  }),
  /**
  * Disabled - Does the button render as disabled
  */
  disabled: PropTypes.bool,
  /**
   * Button label, accepts text labels or SVGs
   */
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
  /**
   * Design theme
   */
  theme: PropTypes.shape({
    style: PropTypes.string,
    type: PropTypes.string
  }),
  /**
   * Type of button
   */
  type: PropTypes.string
}

Button.defaultProps = {
  label: 'Missing label text',
  theme: {
    style: 'primary',
    type: 'button'
  },
  type: 'button'
}

export default Button
