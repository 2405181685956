import { MapTo } from '@adobe/aem-react-editable-components'
import withAsyncImport from 'utils/withAsyncImport'

const NewsList = withAsyncImport(() =>
  import('./NewsList')
)
const NewsListEditableConfig = {
  emptyLabel: 'News List',

  isEmpty: function (props) {
    return !props || !props.configured
  }
}

export default MapTo('aramark/components/content/news-list')(NewsList, NewsListEditableConfig)
