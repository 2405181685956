

const Translation = {

  form: {
    en: [
      {
        'Name': 'Name',
        'First Name': 'First Name',
        'Last Name': 'Last Name',
        'Organization': 'Organization',
        'Industry': 'Industry',
        'Email Address': 'Email Address',
        'Phone Number': 'Phone Number',
        'Comment / Questions': 'Comment / Questions',
        'Name of location or venue': 'Name of location or venue',
        'Zip / Postal Code': 'Zip/Postal Code',
        'Zip/Postal Code': 'Zip/Postal Code',
        'State': 'State',
        'Inquiry Type': 'Inquiry Type',
        'Customer, Invoice, or Order #': 'Customer, Invoice, or Order #',
        'Type of Services Received': 'Type of Services Received',
        'Tell us about your needs': 'Tell us about your needs',
        'Record Type': 'Record Type',
        'Submit': 'Submit',
        'Service Interested in': 'Service Interested in',
        'Yes, I want to receive communications by e-mail about our services.': 'Yes, I want to receive communications by e-mail about our services.',
        '*I have read and accept the privacy policy.': '*I have read and accept the privacy policy.',
        'is required': 'is required',
        'must be more than': 'must be more than',
        'must be shorter than': 'must be shorter than',
        'must have at least': 'must have at least',
        'can only have': 'can only have',
        'is in an incorrect format': 'is in an incorrect format',
        'characters': 'characters',
        'cannot be validated': 'cannot be validated',
        'is invalid': 'is invalid',
        'Business Workplace': 'Business Workplace',
        'Please correct the following errors:': 'Please correct the following errors:',
        //myPayForm
        'Enter your former 9-digit Aramark Employee ID': 'Enter your former 9-digit Aramark Employee ID. If your Employee ID is less than 9-digits, enter it with a leading "0." For example, if your Employee ID is 12345678, enter 012345678. </br> If you do not remember your Aramark Employee ID, please contact Aramark Payment Services at US 615-864-9298 or CN 1- 800-729-5432 for additional support.',
        'Former Aramark Employee ID': 'Former Aramark Employee ID',
        'Current Email Address': 'Current Email Address',
        'Date of Birth': 'Date of Birth',
        'Select your Country': 'Select your Country',
        'US': 'US',
        'Canada': 'Canada',
        'Social Security Number': 'Social Security Number',
        'Please enter the last 4 digits of your SSN': 'Please enter the last 4 digits of your SSN',
        'Social Insurance Number': 'Social Insurance Number',
        'Please enter the last 3 digits of your SIN': 'Please enter the last 3 digits of your SIN',
        'My Pay Submit':'Submit'
      }

    ],
    en_ca: [
      {
        'Name': 'Name',
        'First Name': 'First Name',
        'Last Name': 'Last Name',
        'Organization': 'Organization',
        'Industry': 'Industry',
        'Email Address': 'Email Address',
        'Phone Number': 'Phone Number',
        'Comment / Questions': 'Comment / Questions / Feedback',
        'Name of location or venue': 'Name of location or venue',
        'Zip / Postal Code': 'Postal Code',
        'Zip/Postal Code': 'Postal Code',
        'State': 'Provinces and Territories',
        'Inquiry Type': 'Inquiry Type',
        'Customer, Invoice, or Order #': 'Customer, Invoice, or Order #',
        'Type of Services Received': 'Type of Services Received',
        'Tell us about your needs': 'Tell us about your needs',
        'Record Type': 'Record Type',
        'Submit': 'Submit',
        'Service Interested in': 'Service Interested in',
        'Yes, I want to receive communications by e-mail about our services.': 'Yes, I want to receive communications by email about our services.',
        '*I have read and accept the privacy policy.': '*I have read and accept the privacy policy',
        'is required': 'is required',
        'must be more than': 'must be more than',
        'must be shorter than': 'must be shorter than',
        'must have at least': 'must have at least',
        'can only have': 'can only have',
        'is in an incorrect format': 'is in an incorrect format',
        'characters': 'characters',
        'cannot be validated': 'cannot be validated',
        'is invalid': 'is invalid',
        'Business Workplace': 'Business Workplace',
        'Manufacturing & Industry': 'Manufacturing & Industry',
        'Colleges & Universities': 'Colleges & Universities',
        'Health & Care': 'Health & Care',
        'Defence': '',
        'Retail': 'Retail',
        'Sports & Entertainment': 'Sports & Entertainment',
        'Destinations & Cultural Attractions': 'Destinations & Cultural Attractions',
        'Extractive Industries': 'Extractive Industries',
        'Facilities Management': 'Facilities Management',
        'Please correct the following errors:': 'Please correct the following errors:',
        'Business & Industry':'Business & Industry',
        'K-12 and Independent Schools':'K-12 and Independent Schools',
        'Healthcare':'Healthcare',
        'Senior Living':'Senior Living',
        'Remote Services':'Remote Services',
        'Patient Dining':'Patient Dining',
        'Retail Food Services':'Retail Food Services',
        'Catering & Conference Services':'Catering & Conference Services',
        'Refreshment Services':'Refreshment Services',
        'Other':'Other',
        'Comment':'Comment',
        'Our Services':'Our Services',

        //myPayForm
        'Enter your former 9-digit Aramark Employee ID':'Enter your former 9-digit Aramark Employee ID. If your Employee ID is less than 9-digits, enter it with a leading "0." For example, if your Employee ID is 12345678, enter 012345678. </br> If you do not remember your Aramark Employee ID, please contact Aramark Payment Services at US 615-864-9298 or CN 1- 800-729-5432 for additional support.',
        'Former Aramark Employee ID': 'Former Aramark Employee ID',
        'Current Email Address': 'Current Email Address',
        'Date of Birth': 'Date of Birth',
        'Select your Country': 'Select your Country',
        'US': 'US',
        'Canada': 'Canada',
        'Social Security Number': 'Social Security Number',
        'Please enter the last 4 digits of your SSN': 'Please enter the last 4 digits of your SSN',
        'Social Insurance Number': 'Social Insurance Number',
        'Please enter the last 3 digits of your SIN': 'Please enter the last 3 digits of your SIN',
        'My Pay Submit':'Submit'
      }
    ],
    fr_ca: [
      {
        'Name': 'Nom',
        'First Name': 'Prénom',
        'Last Name': 'Nom de famille',
        'Organization': 'Organisation',
        'Industry': 'Industrie',
        'Email Address': 'Adresse courriel',
        'Phone Number': 'Numéro de téléphone',
        'Comment / Questions': 'Commentaire / Questions / Retour d\'information',
        'Name of location or venue': 'Nom du lieu ou du lieu',
        'Zip / Postal Code': 'Code postal',
        'Zip/Postal Code': 'Code postal',
        'State': 'Provinces et Territoires',
        'Inquiry Type': 'Type de Demande',
        'Customer, Invoice, or Order #': 'Client, facture ou commande #',
        'Type of Services Received': 'Type de services reçus',
        'Tell us about your needs': 'Parlez-nous de vos besoins',
        'Record Type': 'Type d\'enregistrement',
        'Submit': 'Envoyer',
        'Service Interested in': 'Intéressé par',
        'Yes, I want to receive communications by e-mail about our services.': 'Oui, je souhaite recevoir des communications par courriel concernant nos services.',
        '*I have read and accept the privacy policy.': '*J\'ai lu et j\'accepte la politique de confidentialité.',
        'is required': 'est requis',
        'must be more than': 'doit contenir plus de',
        'must be shorter than': 'doit être plus court que',
        'must have at least': 'doit comporter au moins',
        'can only have': 'ne peut contenir que',
        'is in an incorrect format': 'a un format incorrect',
        'characters': 'caractères',
        'cannot be validated': 'ne peut pas être validé',
        'is invalid': 'est invalide',
        'Business Workplace': 'Lieu de travail',
        'Manufacturing & Industry': 'Secteur manufacturier et industriel',
        'Colleges & Universities': 'Collèges et universités',
        'Health & Care': 'Santé et soins',
        'Defence': 'Défense',
        'Retail': 'Commerce de détail',
        'Sports & Entertainment': 'Sports et loisirs',
        'Destinations & Cultural Attractions': 'Destinations et attractions culturelles',
        'Extractive Industries': 'Industries minières',
        'Facilities Management': 'Gestion des installations',
        'Please correct the following errors:': 'Veuillez corriger les erreurs suivantes :',   
        'Business & Industry':'Entreprises et industries',
        'K-12 and Independent Schools':'Écoles primaires et secondaires et écoles indépendantes',
        'Healthcare':'Établissements de santé',
        'Senior Living':'Résidences pour aînés',
        'Remote Services':'Services en campements éloignés',
        'Patient Dining':'Restauration des patients',
        'Retail Food Services':'Services alimentaires de détail',
        'Catering & Conference Services':"Services de restauration d'événements et de conférences",
        'Refreshment Services':'Services de rafraîchissement',
        'Other':'Autre',
        'Comment':'Commentaire',
        'Our Services':'Nos services',

        //myPayForm
        'Enter your former 9-digit Aramark Employee ID': 'Entrez votre ancien ID à 9 chiffres d’employé d’Aramark. Si votre ID d’employé comporte moins de 9 chiffres, entrez-le précédé d’un zéro (0). Par exemple, si votre ID d’employé est 12345678, entrez 012345678.<br>Si vous avez oublié votre ID d’employé d’Aramark, veuillez contacter les Services de paiement d’Aramark en composant le 615-864-9298 (aux États-Unis) ou le 1 800 729-5432 (au Canada) pour obtenir de l’aide.',
        'Former Aramark Employee ID': 'Ancien ID d’employé d’Aramark',
        'Current Email Address': 'Adresse de courriel actuelle',
        'Date of Birth': 'Date de naissance',
        'Select your Country': 'Sélectionnez votre pays',
        'US': 'États-Unis',
        'Canada': 'Canada',
        'Social Security Number': 'Numéro de sécurité sociale',
        'Please enter the last 4 digits of your SSN': 'Veuillez entrer les 4 derniers chiffres de votre NSS',
        'Social Insurance Number': 'Numéro d’assurance sociale',
        'Please enter the last 3 digits of your SIN': 'Veuillez entrer les 3 derniers chiffres de votre NAS',
        'My Pay Submit':'SOUMETTRE'
      }
    ],
    en_gb: [
      {
        'Name': 'Name',
        'First Name': 'First Name',
        'Last Name': 'Last Name',
        'Organization': 'Organization',
        'Industry': 'Industry',
        'Email Address': 'Email Address',
        'Phone Number': 'Phone Number',
        'Comment / Questions': 'Comment / Questions',
        'Name of location or venue': 'Name of location or venue',
        'Zip / Postal Code': 'Zip/Postal Code',
        'Zip/Postal Code': 'Zip/Postal Code',
        'State': 'State',
        'Inquiry Type': 'Inquiry Type',
        'Customer, Invoice, or Order #': 'Customer, Invoice, or Order #',
        'Type of Services Received': 'Type of Services Received',
        'Tell us about your needs': 'Tell us about your needs',
        'Record Type': 'Record Type',
        'Submit': 'Submit',
        'Service Interested in': 'Service Interested in',
        'Yes, I want to receive communications by e-mail about our services.': 'Yes, I want to receive communications by e-mail about our services.',
        '*I have read and accept the privacy policy.': '*I have read and accept the privacy policy.',
        'is required': 'is required',
        'must be more than': 'must be more than',
        'must be shorter than': 'must be shorter than',
        'must have at least': 'must have at least',
        'can only have': 'can only have',
        'is in an incorrect format': 'is in an incorrect format',
        'characters': 'characters',
        'cannot be validated': 'cannot be validated',
        'is invalid': 'is invalid',
        'Business Workplace': 'Business Workplace',
        'Manufacturing & Industry': 'Manufacturing & Industry',
        'Colleges & Universities': 'Colleges & Universities',
        'Health & Care': 'Health & Care',
        'Defence': 'Defence',
        'Retail': 'Retail',
        'Sports & Entertainment': 'Sports & Entertainment',
        'Destinations & Cultural Attractions': 'Destinations & Cultural Attractions',
        'Extractive Industries': 'Extractive Industries',
        'Facilities Management': 'Facilities Management',
        'Please correct the following errors:': 'Please correct the following errors:',
        //myPayForm
        'Enter your former 9-digit Aramark Employee ID': 'Enter your former 9-digit Aramark Employee ID. If your Employee ID is less than 9-digits, enter it with a leading "0." For example, if your Employee ID is 12345678, enter 012345678. </br> If you do not remember your Aramark Employee ID, please contact Aramark Payment Services at US 615-864-9298 or CN 1- 800-729-5432 for additional support.',
        'Former Aramark Employee ID': 'Former Aramark Employee ID',
        'Current Email Address': 'Current Email Address',
        'Date of Birth': 'Date of Birth',
        'Select your Country': 'Select your Country',
        'US': 'US',
        'Canada': 'Canada',
        'Social Security Number': 'Social Security Number',
        'Please enter the last 4 digits of your SSN': 'Please enter the last 4 digits of your SSN',
        'Social Insurance Number': 'Social Insurance Number',
        'Please enter the last 3 digits of your SIN': 'Please enter the last 3 digits of your SIN',
        'My Pay Submit':'Submit'
      }
    ],
    en_ie: [
      {
        'Name': 'Name',
        'First Name': 'First Name',
        'Last Name': 'Last Name',
        'Organization': 'Organization',
        'Industry': 'Industry',
        'Email Address': 'Email Address',
        'Phone Number': 'Phone Number',
        'Comment / Questions': 'Comment / Questions',
        'Name of location or venue': 'Name of location or venue',
        'Zip / Postal Code': 'Zip/Postal Code',
        'Zip/Postal Code': 'Zip/Postal Code',
        'State': 'State',
        'Inquiry Type': 'Inquiry Type',
        'Customer, Invoice, or Order #': 'Customer, Invoice, or Order #',
        'Type of Services Received': 'Type of Services Received',
        'Tell us about your needs': 'Tell us about your needs',
        'Record Type': 'Record Type',
        'Submit': 'Submit',
        'Service Interested in': 'Service Interested in',
        'Yes, I want to receive communications by e-mail about our services.': 'Yes, I want to receive communications by e-mail about our services.',
        '*I have read and accept the privacy policy.': '*I have read and accept the privacy policy.',
        'is required': 'is required',
        'must be more than': 'must be more than',
        'must be shorter than': 'must be shorter than',
        'must have at least': 'must have at least',
        'can only have': 'can only have',
        'is in an incorrect format': 'is in an incorrect format',
        'characters': 'characters',
        'cannot be validated': 'cannot be validated',
        'is invalid': 'is invalid',
        'Business Workplace': 'Business Workplace',
        'Manufacturing & Industry': 'Manufacturing & Industry',
        'Colleges & Universities': 'Colleges & Universities',
        'Health & Care': 'Health & Care',
        'Defence': 'Defence',
        'Retail': 'Retail',
        'Sports & Entertainment': 'Sports & Entertainment',
        'Destinations & Cultural Attractions': 'Destinations & Cultural Attractions',
        'Extractive Industries': 'Extractive Industries',
        'Facilities Management': 'Facilities Management',
        'Please correct the following errors:': 'Please correct the following errors:',
        //myPayForm
        'Enter your former 9-digit Aramark Employee ID': 'Enter your former 9-digit Aramark Employee ID. If your Employee ID is less than 9-digits, enter it with a leading "0." For example, if your Employee ID is 12345678, enter 012345678. </br> If you do not remember your Aramark Employee ID, please contact Aramark Payment Services at US 615-864-9298 or CN 1- 800-729-5432 for additional support.',
        'Former Aramark Employee ID': 'Former Aramark Employee ID',
        'Current Email Address': 'Current Email Address',
        'Date of Birth': 'Date of Birth',
        'Select your Country': 'Select your Country',
        'US': 'US',
        'Canada': 'Canada',
        'Social Security Number': 'Social Security Number',
        'Please enter the last 4 digits of your SSN': 'Please enter the last 4 digits of your SSN',
        'Social Insurance Number': 'Social Insurance Number',
        'Please enter the last 3 digits of your SIN': 'Please enter the last 3 digits of your SIN',
        'My Pay Submit':'Submit'
      }
    ],
    en_cl: [
      {
        'Name': 'Name',
        'First Name': 'First Name',
        'Last Name': 'Last Name',
        'Organization': 'Organization',
        'Industry': 'Industry',
        'Email Address': 'Email Address',
        'Phone Number': 'Phone Number',
        'Comment / Questions': 'Comment / Questions',
        'Name of location or venue': 'Name of location or venue',
        'Zip / Postal Code': 'Zip/Postal Code',
        'Zip/Postal Code': 'Zip/Postal Code',
        'State': 'State',
        'Inquiry Type': 'Inquiry Type',
        'Customer, Invoice, or Order #': 'Customer, Invoice, or Order #',
        'Type of Services Received': 'Type of Services Received',
        'Tell us about your needs': 'Tell us about your needs',
        'Record Type': 'Record Type',
        'Submit': 'Submit',
        'Service Interested in': 'Service Interested in',
        'Yes, I want to receive communications by e-mail about our services.': 'Yes, I would like to receive communications and give hereby my consent to the processing of my personal data for this purpose. I am aware that I can revoke my consent for the future at any time by emailing vega-judith@aramark.cl. For more information, please see the privacy notice',
        '*I have read and accept the privacy policy.': '*I have read and accept the privacy policy.',
        'is required': 'is required',
        'must be more than': 'must be more than',
        'must be shorter than': 'must be shorter than',
        'must have at least': 'must have at least',
        'can only have': 'can only have',
        'is in an incorrect format': 'is in an incorrect format',
        'characters': 'characters',
        'cannot be validated': 'cannot be validated',
        'is invalid': 'is invalid',
        'Business Workplace': 'Business Workplace',
        'Manufacturing & Industry': 'Manufacturing & Industry',
        'Colleges & Universities': 'Colleges & Universities',
        'Health & Care': 'Health & Care',
        'Defence': 'Defence',
        'Retail': 'Retail',
        'Sports & Entertainment': 'Sports & Entertainment',
        'Destinations & Cultural Attractions': 'Destinations & Cultural Attractions',
        'Extractive Industries': 'Extractive Industries',
        'Facilities Management': 'Facilities Management',
        'Please correct the following errors:': 'Please correct the following errors:',
        //myPayForm
        'Enter your former 9-digit Aramark Employee ID': 'Enter your former 9-digit Aramark Employee ID. If your Employee ID is less than 9-digits, enter it with a leading "0." For example, if your Employee ID is 12345678, enter 012345678. </br> If you do not remember your Aramark Employee ID, please contact Aramark Payment Services at US 615-864-9298 or CN 1- 800-729-5432 for additional support.',
        'Former Aramark Employee ID': 'Former Aramark Employee ID',
        'Current Email Address': 'Current Email Address',
        'Date of Birth': 'Date of Birth',
        'Select your Country': 'Select your Country',
        'US': 'US',
        'Canada': 'Canada',
        'Social Security Number': 'Social Security Number',
        'Please enter the last 4 digits of your SSN': 'Please enter the last 4 digits of your SSN',
        'Social Insurance Number': 'Social Insurance Number',
        'Please enter the last 3 digits of your SIN': 'Please enter the last 3 digits of your SIN',
        'My Pay Submit':'Submit'
      }
    ],
    es_cl: [
      {
        'Name': 'Nombre',
        'First Name': 'Nombre',
        'Last Name': 'Apellido',
        'Organization': 'Nombre de la Empresa',
        'Industry': 'Tipo de negocio',
        'Email Address': 'Dirección de correo electrónico',
        'Phone Number': 'Número de teléfono',
        'Comment / Questions': 'Mensaje / Preguntas',
        'Name of location or venue': 'Nombre del lugar o lugar',
        'Zip / Postal Code': 'Comuna',
        'Zip/Postal Code': 'Comuna',
        'State': 'Ciudad',
        'Inquiry Type': 'Tipo de pregunta',
        'Customer, Invoice, or Order #': 'Cliente, factura o pedido #',
        'Type of Services Received': 'Tipo de servicios recibidos',
        'Tell us about your needs': 'Cuéntenos sobre sus necesidades',
        'Record Type': 'Tipo de registro',
        'Submit': 'Enviar',
        'Service Interested in': 'Servicio Interesado En',
        'Yes, I want to receive communications by e-mail about our services.': 'Sí, quiero recibir comunicaciones por e-mail de sus servicios.',
        '*I have read and accept the privacy policy.': '*He leído y acepto el aviso de privacidad.',
        'is required': '*requerido',
        'must be more than': 'el campo debe ser mayor que',
        'must be shorter than': 'el campo debe ser menor que',
        'must have at least': 'el campo debe tener al menos',
        'can only have': 'el campo solo puede tener',
        'is in an incorrect format': 'Formato incorrecto',
        'characters': 'caracteres',
        'cannot be validated': 'no puede ser validado',
        'is invalid': 'no es válido',
        'Business Workplace': 'Empresas',
        'Manufacturing & Industry': 'Industrias',
        'Colleges & Universities': 'Colegios',
        'Health & Care': 'Salud',
        'Defence': 'Minería y sitios remotos',
        'Retail': 'Retail',
        'Sports & Entertainment': 'Sports & Entertainment',
        'Destinations & Cultural Attractions': 'Destinations & Cultural Attractions',
        'Extractive Industries': 'Extractive Industries',
        'Facilities Management': 'Facilities Management',
        'Please correct the following errors:': 'Por favor arreglar los siguientes errores:',
        //myPayForm
        'Enter your former 9-digit Aramark Employee ID': 'Enter your former 9-digit Aramark Employee ID. If your Employee ID is less than 9-digits, enter it with a leading "0." For example, if your Employee ID is 12345678, enter 012345678. </br> If you do not remember your Aramark Employee ID, please contact Aramark Payment Services at US 615-864-9298 or CN 1- 800-729-5432 for additional support.',
        'Former Aramark Employee ID': 'Former Aramark Employee ID',
        'Current Email Address': 'Current Email Address',
        'Date of Birth': 'Date of Birth',
        'Select your Country': 'Select your Country',
        'US': 'US',
        'Canada': 'Canada',
        'Social Security Number': 'Social Security Number',
        'Please enter the last 4 digits of your SSN': 'Please enter the last 4 digits of your SSN',
        'Social Insurance Number': 'Social Insurance Number',
        'Please enter the last 3 digits of your SIN': 'Please enter the last 3 digits of your SIN',
        'My Pay Submit':'Submit'
      }
    ],
    en_es: [
      {
        'Name': 'Name',
        'First Name': 'First Name',
        'Last Name': 'Last Name',
        'Organization': 'Organization',
        'Industry': 'Industry',
        'Email Address': 'Email Address',
        'Phone Number': 'Phone Number',
        'Comment / Questions': 'Comment / Questions',
        'Name of location or venue': 'Name of location or venue',
        'Zip / Postal Code': 'Zip/Postal Code',
        'Zip/Postal Code': 'Zip/Postal Code',
        'State': 'Province',
        'Inquiry Type': 'Inquiry Type',
        'Customer, Invoice, or Order #': 'Customer, Invoice, or Order #',
        'Type of Services Received': 'Type of Services Received',
        'Tell us about your needs': 'Tell us about your needs',
        'Record Type': 'Record Type',
        'Submit': 'Submit',
        'Service Interested in': 'Service Interested in',
        'Yes, I want to receive communications by e-mail about our services.': 'Yes, I want to receive communications by e-mail about our services.',
        '*I have read and accept the privacy policy.': '*I have read and accept the privacy policy.',
        'is required': 'is required',
        'must be more than': 'must be more than',
        'must be shorter than': 'must be shorter than',
        'must have at least': 'must have at least',
        'can only have': 'can only have',
        'is in an incorrect format': 'is in an incorrect format',
        'characters': 'characters',
        'cannot be validated': 'cannot be validated',
        'is invalid': 'is invalid',
        'Business Workplace': 'Business Workplace',
        'Manufacturing & Industry': 'Manufacturing & Industry',
        'Colleges & Universities': 'Colleges & Universities',
        'Health & Care': 'Health & Care',
        'Defence': 'Defence',
        'Retail': 'Retail',
        'Sports & Entertainment': 'Sports & Entertainment',
        'Destinations & Cultural Attractions': 'Destinations & Cultural Attractions',
        'Extractive Industries': 'Extractive Industries',
        'Facilities Management': 'Facilities Management',
        'Please correct the following errors:': 'Please correct the following errors:',
        //myPayForm
        'Enter your former 9-digit Aramark Employee ID': 'Enter your former 9-digit Aramark Employee ID. If your Employee ID is less than 9-digits, enter it with a leading "0." For example, if your Employee ID is 12345678, enter 012345678. </br> If you do not remember your Aramark Employee ID, please contact Aramark Payment Services at US 615-864-9298 or CN 1- 800-729-5432 for additional support.',
        'Former Aramark Employee ID': 'Former Aramark Employee ID',
        'Current Email Address': 'Current Email Address',
        'Date of Birth': 'Date of Birth',
        'Select your Country': 'Select your Country',
        'US': 'US',
        'Canada': 'Canada',
        'Social Security Number': 'Social Security Number',
        'Please enter the last 4 digits of your SSN': 'Please enter the last 4 digits of your SSN',
        'Social Insurance Number': 'Social Insurance Number',
        'Please enter the last 3 digits of your SIN': 'Please enter the last 3 digits of your SIN',
        'My Pay Submit':'Submit'

      }
    ],
    es_es: [
      {
        'Name': 'Nombre',
        'First Name': 'Nombre',
        'Last Name': 'Apellido',
        'Organization': 'Organización',
        'Industry': 'Sector',
        'Email Address': 'Dirección de correo electrónico',
        'Phone Number': 'Número de teléfono',
        'Comment / Questions': 'Comentario / Preguntas',
        'Name of location or venue': 'Nombre del lugar o lugar',
        'Zip / Postal Code': 'Código postal',
        'Zip/Postal Code': 'Código postal',
        'State': 'Provincia',
        'Inquiry Type': 'Tipo de consulta',
        'Customer, Invoice, or Order #': 'Cliente, factura o pedido #',
        'Type of Services Received': 'Tipo de servicios recibidos',
        'Tell us about your needs': 'Cuéntenos sobre sus necesidades',
        'Record Type': 'Tipo de registro',
        'Submit': 'Enviar',
        'Service Interested in': 'Servicio Interesado En',
        'Yes, I want to receive communications by e-mail about our services.': 'Sí, quiero recibir comunicaciones de carácter por correo electrónico de vuestros servicios.',
        '*I have read and accept the privacy policy.': '*He leído y acepto la Política de privacidad.',
        'is required': 'Campo requerido',
        'must be more than': 'debe ser mayor que',
        'must be shorter than': 'debe ser menor que',
        'must have at least': 'debe contener al menos',
        'can only have': 'solo puede tener',
        'is in an incorrect format': 'formato incorrecto',
        'characters': 'caracteres',
        'cannot be validated': 'no se puede validar',
        'is invalid': 'no válido',
        'Business Workplace': 'Empresas',
        'Manufacturing & Industry': 'Manufactura e Industria',
        'Colleges & Universities': 'Colegios y universidades',
        'Health & Care': 'Hospitales y residencias',
        'Defence': 'Defensa',
        'Retail': 'Retail',
        'Sports & Entertainment': 'Deportes y entretenimiento',
        'Destinations & Cultural Attractions': 'Destinos turísticos y atracciones culturales',
        'Extractive Industries': 'Minería',
        'Facilities Management': 'Gestión de instalaciones',
        'Please correct the following errors:': 'Por favor corrige los siguientes errores:',
         //myPayForm
        'Enter your former 9-digit Aramark Employee ID':'Ingrese su ID antigua de Empleado de Aramark de 9 dígitos. Si su ID de Empleado tiene menos de 9 dígitos, ingréselo con un "0" al inicio. Por ejemplo, si su ID de Empleado es 12345678, ingrese 012345678.<br>Si no recuerda su ID de Empleado de Aramark, póngase en contacto con los servicios de pagos de Aramark al EE.UU. 615-864-9298 o Canadá 1-800-729-5432 para obtener ayuda adicional.',
        'Former Aramark Employee ID': 'ID de empleado antigua de Aramark',
        'Current Email Address': 'Dirección de correo electrónico actual',
        'Date of Birth': 'Fecha de nacimiento',
        'Select your Country': 'Seleccione su país',
        'US': 'EE. UU.',
        'Canada': 'Canadá',
        'Social Security Number': 'Número de Seguridad Social',
        'Please enter the last 4 digits of your SSN': 'Por favor ingrese los últimos 4 dígitos de su número de Seguridad Social',
        'Social Insurance Number': 'Número de Seguro Social',
        'Please enter the last 3 digits of your SIN': 'Por favor ingrese los últimos 3 dígitos de su número de Seguro Social',
        'My Pay Submit':'ENVIAR'
      }
    ],
    ca_es: [
      {
        'Name': 'Name',
        'First Name': 'Nom',
        'Last Name': 'Cognom',
        'Organization': 'Organització',
        'Industry': 'Sector',
        'Email Address': 'Adreça de correu electrònic',
        'Phone Number': 'Número de telèfon',
        'Comment / Questions': 'Comentaris / Preguntes',
        'Name of location or venue': 'Naam van locatie of locatie',
        'Zip / Postal Code': 'Codi postal',
        'Zip/Postal Code': 'Codi postal',
        'State': 'Província',
        'Inquiry Type': 'Tipus de consulta',
        'Customer, Invoice, or Order #': 'Klant-, factuur- of ordernummer #',
        'Type of Services Received': 'Type ontvangen diensten',
        'Tell us about your needs': 'Vertel ons over uw behoeften',
        'Record Type': 'Recordtype',
        'Submit': 'Enviar',
        'Service Interested in': 'Dienst Geïnteresseerd in',
        'Yes, I want to receive communications by e-mail about our services.': 'Sí, vull rebre comunicacions de caràcter per correu electrònic dels vostres serveis.',
        '*I have read and accept the privacy policy.': '*He llegit i accepto la Política de privacitat.',
        'is required': 'Camp requerit',
        'must be more than': 'ha de ser més gran que',
        'must be shorter than': 'ha de ser menor que',
        'must have at least': 'ha de contenir almenys',
        'can only have': 'només pot tenir',
        'is in an incorrect format': 'format incorrecte',
        'characters': 'caràcters',
        'cannot be validated': 'no es pot validar',
        'is invalid': 'no vàlid',
        'Business Workplace': 'Empreses',
        'Manufacturing & Industry': 'Manufactura i Indústria',
        'Colleges & Universities': 'Col·legis i universitats',
        'Health & Care': 'Hospitals i residències',
        'Defence': 'Defensa',
        'Retail': 'Retail',
        'Sports & Entertainment': 'Esports i entreteniment',
        'Destinations & Cultural Attractions': 'Destinacions turístiques i atraccions culturals',
        'Extractive Industries': 'Mineria',
        'Facilities Management': 'Gestió d\'instal·lacions',
        'Please correct the following errors:': 'Si us plau corregeix els errors següents:',
        //myPayForm
        'Enter your former 9-digit Aramark Employee ID': 'Enter your former 9-digit Aramark Employee ID. If your Employee ID is less than 9-digits, enter it with a leading "0." For example, if your Employee ID is 12345678, enter 012345678. </br> If you do not remember your Aramark Employee ID, please contact Aramark Payment Services at US 615-864-9298 or CN 1- 800-729-5432 for additional support.',
        'Former Aramark Employee ID': 'Former Aramark Employee ID',
        'Current Email Address': 'Current Email Address',
        'Date of Birth': 'Date of Birth',
        'Select your Country': 'Select your Country',
        'US': 'US',
        'Canada': 'Canada',
        'Social Security Number': 'Social Security Number',
        'Please enter the last 4 digits of your SSN': 'Please enter the last 4 digits of your SSN',
        'Social Insurance Number': 'Social Insurance Number',
        'Please enter the last 3 digits of your SIN': 'Please enter the last 3 digits of your SIN',
        'My Pay Submit':'ENVIAR'
      }
    ],
    en_de: [
      {
        'Name': 'Name',
        'First Name': 'First Name',
        'Last Name': 'Last Name',
        'Organization': 'Organization',
        'Industry': 'Industry',
        'Email Address': 'Email Address',
        'Phone Number': 'Phone Number',
        'Comment / Questions': 'Comment / Questions',
        'Name of location or venue': 'Name of location or venue',
        'Zip / Postal Code': 'Zip/Postal Code',
        'Zip/Postal Code': 'Zip/Postal Code',
        'State': 'State',
        'Inquiry Type': 'Inquiry Type',
        'Customer, Invoice, or Order #': 'Customer, Invoice, or Order #',
        'Type of Services Received': 'Type of Services Received',
        'Tell us about your needs': 'Tell us about your needs',
        'Record Type': 'Record Type',
        'Submit': 'Submit',
        'Service Interested in': 'Service Interested in',
        'Yes, I want to receive communications by e-mail about our services.': 'Yes, I would like to receive communications and give hereby my consent to the processing of my personal data for this purpose. I am aware that I can revoke my consent for the future at any time by emailing info@aramark.de. For more information, please see the privacy notice.',
        '*I have read and accept the privacy policy.': '*I have read and understood the privacy notice',
        'is required': 'is required',
        'must be more than': 'must be more than',
        'must be shorter than': 'must be shorter than',
        'must have at least': 'must have at least',
        'can only have': 'can only have',
        'is in an incorrect format': 'is in an incorrect format',
        'characters': 'characters',
        'cannot be validated': 'cannot be validated',
        'is invalid': 'is invalid',
        'Business Workplace': 'Business Workplace',
        'Manufacturing & Industry': 'Manufacturing & Industry',
        'Colleges & Universities': 'Colleges & Universities',
        'Health & Care': 'Health & Care',
        'Defence': 'Defence',
        'Retail': 'Retail',
        'Sports & Entertainment': 'Sports & Entertainment',
        'Destinations & Cultural Attractions': 'Destinations & Cultural Attractions',
        'Extractive Industries': 'Extractive Industries',
        'Facilities Management': 'Facilities Management',
        'Please correct the following errors:': 'Please correct the following errors:',
        //myPayForm
        'Enter your former 9-digit Aramark Employee ID': 'Enter your former 9-digit Aramark Employee ID. If your Employee ID is less than 9-digits, enter it with a leading "0." For example, if your Employee ID is 12345678, enter 012345678. </br> If you do not remember your Aramark Employee ID, please contact Aramark Payment Services at US 615-864-9298 or CN 1- 800-729-5432 for additional support.',
        'Former Aramark Employee ID': 'Former Aramark Employee ID',
        'Current Email Address': 'Current Email Address',
        'Date of Birth': 'Date of Birth',
        'Select your Country': 'Select your Country',
        'US': 'US',
        'Canada': 'Canada',
        'Social Security Number': 'Social Security Number',
        'Please enter the last 4 digits of your SSN': 'Please enter the last 4 digits of your SSN',
        'Social Insurance Number': 'Social Insurance Number',
        'Please enter the last 3 digits of your SIN': 'Please enter the last 3 digits of your SIN',
        'My Pay Submit':'Submit'
      }
    ],
    de_de: [
      {
        'Name': 'Name',
        'First Name': 'Vorname',
        'Last Name': 'Nachname',
        'Organization': 'Firma',
        'Industry': 'Geschäftsfeld',
        'Email Address': 'E-Mail-Adresse',
        'Phone Number': 'Telefonnummer',
        'Comment / Questions': 'Kommentar / Fragen ',
        'Name of location or venue': 'Name des Ortes oder Veranstaltungsortes',
        'Zip / Postal Code': 'PLZ',
        'Zip/Postal Code': 'PLZ',
        'State': 'Land',
        'Inquiry Type': 'Art der Anfrage',
        'Customer, Invoice, or Order #': 'Kunden-, Rechnungs- oder Bestellnummer #',
        'Type of Services Received': 'Art der erhaltenen Dienstleistungen',
        'Tell us about your needs': 'Erzählen Sie uns von Ihren Bedürfnissen',
        'Record Type': 'Aufnahmetyp',
        'Submit': 'Absenden',
        'Service Interested in': 'Dienst interessiert an',
        'Yes, I want to receive communications by e-mail about our services.': 'Ja, ich möchte Benachrichtigungen von Aramark erhalten, und erteile mein Einverständnis in die Verarbeitung meiner E-Mail-Adresse zu diesem Zweck. Mir ist bekannt, dass ich mein Einverständnis jederzeit für die Zukunft per E-Mail an datenschutzbeauftrager@aramark.de widerrufen kann. Mehr Informationen können der Datenschutzerklärung entnommen werden.',
        '*I have read and accept the privacy policy.': '*Ich habe die Datenschutzerklärung gelesen und zur Kenntnis genommen.',
        'is required': 'Angabe erforderlich',
        'must be more than': 'muss länger sein als',
        'must be shorter than': 'muss kürzer sein als',
        'must have at least': 'mindestens notwendig',
        'can only have': 'darf nur haben',
        'is in an incorrect format': 'Falsches Format',
        'characters': 'Zeichen',
        'cannot be validated': 'kann nicht akzeptiert werden',
        'is invalid': 'ist ungültig',
        'Business Workplace': 'Workplace Experience',
        'Manufacturing & Industry': 'Manufacturing & Industry',
        'Colleges & Universities': 'Colleges & Universities',
        'Health & Care': 'Health & Care',
        'Defence': 'Defence',
        'Retail': 'Retail',
        'Sports & Entertainment': 'Sports & Event Catering',
        'Destinations & Cultural Attractions': 'Refreshment Services',
        'Extractive Industries': 'Extractive Industries',
        'Facilities Management': 'Facilities Management',
        'Please correct the following errors:': 'Bitte korrigieren Sie folgende Fehler:',
        //myPayForm
        'Enter your former 9-digit Aramark Employee ID': 'Enter your former 9-digit Aramark Employee ID. If your Employee ID is less than 9-digits, enter it with a leading "0." For example, if your Employee ID is 12345678, enter 012345678. </br> If you do not remember your Aramark Employee ID, please contact Aramark Payment Services at US 615-864-9298 or CN 1- 800-729-5432 for additional support.',
        'Former Aramark Employee ID': 'Former Aramark Employee ID',
        'Current Email Address': 'Current Email Address',
        'Date of Birth': 'Date of Birth',
        'Select your Country': 'Select your Country',
        'US': 'US',
        'Canada': 'Canada',
        'Social Security Number': 'Social Security Number',
        'Please enter the last 4 digits of your SSN': 'Please enter the last 4 digits of your SSN',
        'Social Insurance Number': 'Social Insurance Number',
        'Please enter the last 3 digits of your SIN': 'Please enter the last 3 digits of your SIN',
        'My Pay Submit':'Absenden'
      }
    ],
    en_kr: [
      {
        'Name': 'Name',
        'First Name': 'First Name',
        'Last Name': 'Last Name',
        'Organization': 'Organization',
        'Industry': 'Industry',
        'Email Address': 'Email Address',
        'Phone Number': 'Phone Number',
        'Comment / Questions': 'Comment / Questions',
        'Name of location or venue': 'Name of location or venue',
        'Zip / Postal Code': 'Zip/Postal Code',
        'Zip/Postal Code': 'Zip/Postal Code',
        'State': 'State',
        'Inquiry Type': 'Inquiry Type',
        'Customer, Invoice, or Order #': 'Customer, Invoice, or Order #',
        'Type of Services Received': 'Type of Services Received',
        'Tell us about your needs': 'Tell us about your needs',
        'Record Type': 'Record Type',
        'Submit': 'Submit',
        'Service Interested in': 'Service Interested in',
        'Yes, I want to receive communications by e-mail about our services.': 'Yes, I want to receive communications by e-mail about our services.',
        '*I have read and accept the privacy policy.': '*I have read and accept the privacy policy.',
        'is required': 'is required',
        'must be more than': 'must be more than',
        'must be shorter than': 'must be shorter than',
        'must have at least': 'must have at least',
        'can only have': 'can only have',
        'is in an incorrect format': 'is in an incorrect format',
        'characters': 'characters',
        'cannot be validated': 'cannot be validated',
        'is invalid': 'is invalid',
        'Business Workplace': 'Business Workplace',
        'Manufacturing & Industry': 'Manufacturing & Industry',
        'Colleges & Universities': 'Colleges & Universities',
        'Health & Care': 'Health & Care',
        'Defence': 'Defence',
        'Retail': 'Retail',
        'Sports & Entertainment': 'Sports & Entertainment',
        'Destinations & Cultural Attractions': 'Destinations & Cultural Attractions',
        'Extractive Industries': 'Extractive Industries',
        'Facilities Management': 'Facilities Management',
        'Please correct the following errors:': 'Please correct the following errors:',
        //myPayForm
        'Enter your former 9-digit Aramark Employee ID': 'Enter your former 9-digit Aramark Employee ID. If your Employee ID is less than 9-digits, enter it with a leading "0." For example, if your Employee ID is 12345678, enter 012345678. </br> If you do not remember your Aramark Employee ID, please contact Aramark Payment Services at US 615-864-9298 or CN 1- 800-729-5432 for additional support.',
        'Former Aramark Employee ID': 'Former Aramark Employee ID',
        'Current Email Address': 'Current Email Address',
        'Date of Birth': 'Date of Birth',
        'Select your Country': 'Select your Country',
        'US': 'US',
        'Canada': 'Canada',
        'Social Security Number': 'Social Security Number',
        'Please enter the last 4 digits of your SSN': 'Please enter the last 4 digits of your SSN',
        'Social Insurance Number': 'Social Insurance Number',
        'Please enter the last 3 digits of your SIN': 'Please enter the last 3 digits of your SIN',
        'My Pay Submit':'Submit'
      }
    ],
    ko_kr: [
      {
        'Name': '이름',
        'First Name': '이름',
        'Last Name': '성',
        'Organization': '조직',
        'Industry': '산업',
        'Email Address': '이메일주소',
        'Phone Number': '전화번호',
        'Comment / Questions': '의견 / 질문',
        'Name of location or venue': '위치 또는 장소 이름',
        'Zip / Postal Code': '우편번호',
        'Zip/Postal Code': '우편번호',
        'State': '주',
        'Inquiry Type': '질문유형',
        'Customer, Invoice, or Order #': '고객, 송장 또는 주문 번호',
        'Type of Services Received': '받은 서비스 유형',
        'Tell us about your needs': '귀하의 필요에 대해 알려주십시오',
        'Record Type': '레코드 유형',
        'Submit': '제출',
        'Service Interested in': '관심 서비스',
        'Yes, I want to receive communications by e-mail about our services.': '본 서비스 관련된 Email 수신에 동의합니다',
        '*I have read and accept the privacy policy.': '*개인 정보 보호 정책을 읽고 동의합니다.',
        'is required': '필수 항목입니다',
        'must be more than': ' 는 보다 커야 합니다',
        'must be shorter than': ' 는 보다 짧아야 합니다',
        'must have at least': '이상이어야 합니다',
        'can only have': '만 있어야 합니다',
        'is in an incorrect format': '잘못된 형식입니다',
        'characters': '자',
        'cannot be validated': '유효성을 확인할 수 없습니다',
        'is invalid': '유효하지 않습니다',
        'Business Workplace': '사무실 ',
        'Manufacturing & Industry': '제조 및 산업',
        'Colleges & Universities': '대학교',
        'Health & Care': '보건 의료',
        'Defence': '방산',
        'Retail': '리테일',
        'Sports & Entertainment': '스포츠 및 이벤트',
        'Destinations & Cultural Attractions': '여행지 및 관광문화지',
        'Extractive Industries': '채취산업',
        'Facilities Management': '시설관리',
        'Please correct the following errors:': '다음 오류를 수정하십시오:',
        //myPayForm
        'Enter your former 9-digit Aramark Employee ID': 'Enter your former 9-digit Aramark Employee ID. If your Employee ID is less than 9-digits, enter it with a leading "0." For example, if your Employee ID is 12345678, enter 012345678. </br> If you do not remember your Aramark Employee ID, please contact Aramark Payment Services at US 615-864-9298 or CN 1- 800-729-5432 for additional support.',
        'Former Aramark Employee ID': 'Former Aramark Employee ID',
        'Current Email Address': 'Current Email Address',
        'Date of Birth': 'Date of Birth',
        'Select your Country': 'Select your Country',
        'US': 'US',
        'Canada': 'Canada',
        'Social Security Number': 'Social Security Number',
        'Please enter the last 4 digits of your SSN': 'Please enter the last 4 digits of your SSN',
        'Social Insurance Number': 'Social Insurance Number',
        'Please enter the last 3 digits of your SIN': 'Please enter the last 3 digits of your SIN',
        'My Pay Submit':'제출'
      }
    ],
    en_be: [
      {
        'Name': 'Name',
        'First Name': 'First Name',
        'Last Name': 'Last Name',
        'Organization': 'Organization',
        'Industry': 'Industry',
        'Email Address': 'Email Address',
        'Phone Number': 'Phone Number',
        'Comment / Questions': 'Comment / Questions',
        'Name of location or venue': 'Name of location or venue',
        'Zip / Postal Code': 'Zip/Postal Code',
        'Zip/Postal Code': 'Zip/Postal Code',
        'State': 'State',
        'Inquiry Type': 'Inquiry Type',
        'Customer, Invoice, or Order #': 'Customer, Invoice, or Order #',
        'Type of Services Received': 'Type of Services Received',
        'Tell us about your needs': 'Tell us about your needs',
        'Record Type': 'Record Type',
        'Submit': 'Submit',
        'Service Interested in': 'Service Interested in',
        'Yes, I want to receive communications by e-mail about our services.': 'Yes, I want to receive communications by e-mail about our services.',
        '*I have read and accept the privacy policy.': '*I have read and accept the privacy policy.',
        'is required': 'is required',
        'must be more than': 'must be more than',
        'must be shorter than': 'must be shorter than',
        'must have at least': 'must have at least',
        'can only have': 'can only have',
        'is in an incorrect format': 'is in an incorrect format',
        'characters': 'characters',
        'cannot be validated': 'cannot be validated',
        'is invalid': 'is invalid',
        'Business Workplace': 'Business Workplace',
        'Manufacturing & Industry': 'Manufacturing & Industry',
        'Colleges & Universities': 'Colleges & Universities',
        'Health & Care': 'Health & Care',
        'Defence': 'Defence',
        'Retail': 'Retail',
        'Sports & Entertainment': 'Sports & Entertainment',
        'Destinations & Cultural Attractions': 'Destinations & Cultural Attractions',
        'Extractive Industries': 'Extractive Industries',
        'Facilities Management': 'Facilities Management',
        'Please correct the following errors:': 'Please correct the following errors:',
        //myPayForm
        'Enter your former 9-digit Aramark Employee ID': 'Enter your former 9-digit Aramark Employee ID. If your Employee ID is less than 9-digits, enter it with a leading "0." For example, if your Employee ID is 12345678, enter 012345678. </br> If you do not remember your Aramark Employee ID, please contact Aramark Payment Services at US 615-864-9298 or CN 1- 800-729-5432 for additional support.',
        'Former Aramark Employee ID': 'Former Aramark Employee ID',
        'Current Email Address': 'Current Email Address',
        'Date of Birth': 'Date of Birth',
        'Select your Country': 'Select your Country',
        'US': 'US',
        'Canada': 'Canada',
        'Social Security Number': 'Social Security Number',
        'Please enter the last 4 digits of your SSN': 'Please enter the last 4 digits of your SSN',
        'Social Insurance Number': 'Social Insurance Number',
        'Please enter the last 3 digits of your SIN': 'Please enter the last 3 digits of your SIN',
        'My Pay Submit':'Submit'
      }
    ],
    fr_be: [
      {
        'Name': 'Nom',
        'First Name': 'Prénom',
        'Last Name': 'Nom de famille',
        'Organization': 'Organisation',
        'Industry': 'Secteur',
        'Email Address': 'Adresse e-mail',
        'Phone Number': 'Numéro de téléphone',
        'Comment / Questions': 'Remarque / Questions',
        'Name of location or venue': 'Nom du lieu ou du lieu',
        'Zip / Postal Code': 'Code postal',
        'Zip/Postal Code': 'Code postal',
        'State': 'Région',
        'Inquiry Type': 'Type de demande',
        'Customer, Invoice, or Order #': 'Client, facture ou commande #',
        'Type of Services Received': 'Type de services reçus',
        'Tell us about your needs': 'Parlez-nous de vos besoins',
        'Record Type': 'Type d\'enregistrement',
        'Submit': 'Envoyer',
        'Service Interested in': 'Intéressé par',
        'Yes, I want to receive communications by e-mail about our services.': 'Oui, je souhaite recevoir des communications par e-mail.',
        '*I have read and accept the privacy policy.': '*J\'ai lu et accepte la politique de confidentialité.',
        'is required': 'Obligatoire',
        'must be more than': 'doit contenir plus de',
        'must be shorter than': 'doit contenir moins de',
        'must have at least': 'doit contenir au moins',
        'can only have': 'ne peut contenir que',
        'is in an incorrect format': 'format incorrect',
        'characters': 'caractères',
        'cannot be validated': 'ne peut pas être validé',
        'is invalid': 'est incorrect',
        'Business Workplace': 'Entreprise',
        'Manufacturing & Industry': 'Usine & industrie',
        'Colleges & Universities': 'Haute école & université',
        'Health & Care': 'Santé & soin',
        'Defence': 'Défense',
        'Retail': 'Retail',
        'Sports & Entertainment': 'Sport & événementiel',
        'Destinations & Cultural Attractions': 'Attractions culturelles',
        'Extractive Industries': 'Industrie d\'extraction',
        'Facilities Management': 'Facility Management',
        'Please correct the following errors:': 'Veuillez corriger les erreurs suivantes:',
        //myPayForm
        'Enter your former 9-digit Aramark Employee ID': 'Entrez votre ancien ID à 9 chiffres d’employé d’Aramark. Si votre ID d’employé comporte moins de 9 chiffres, entrez-le précédé d’un zéro (0). Par exemple, si votre ID d’employé est 12345678, entrez 012345678.<br>Si vous avez oublié votre ID d’employé d’Aramark, veuillez contacter les Services de paiement d’Aramark en composant le 615-864-9298 (aux États-Unis) ou le 1 800 729-5432 (au Canada) pour obtenir de l’aide.',
        'Former Aramark Employee ID': 'Ancien ID d’employé d’Aramark',
        'Current Email Address': 'Adresse de courriel actuelle',
        'Date of Birth': 'Date de naissance',
        'Select your Country': 'Sélectionnez votre pays',
        'US': 'États-Unis',
        'Canada': 'Canada',
        'Social Security Number': 'Numéro de sécurité sociale',
        'Please enter the last 4 digits of your SSN': 'Veuillez entrer les 4 derniers chiffres de votre NSS',
        'Social Insurance Number': 'Numéro d’assurance sociale',
        'Please enter the last 3 digits of your SIN': 'Veuillez entrer les 3 derniers chiffres de votre NAS',
        'My Pay Submit':'SOUMETTRE'
      }
    ],
    nl_be: [
      {
        'Name': 'Naam',
        'First Name': 'Voornaam',
        'Last Name': 'Achternaam',
        'Organization': 'Organisatie',
        'Industry': 'Sector',
        'Email Address': 'e-mailadres',
        'Phone Number': 'Telefoonnummer',
        'Comment / Questions': 'Opmerking / Vragen',
        'Name of location or venue': 'Naam van locatie of locatie',
        'Zip / Postal Code': 'Postcode',
        'Zip/Postal Code': 'Postcode',
        'State': 'Regio',
        'Inquiry Type': 'Soort aanvraag',
        'Customer, Invoice, or Order #': 'Klant, factuur of bestelling #',
        'Type of Services Received': 'Type ontvangen diensten',
        'Tell us about your needs': 'Vertel ons over uw behoeften',
        'Record Type': 'Recordtype',
        'Submit': 'Verzenden',
        'Service Interested in': 'Geïnteresseerd in',
        'is required': 'Verplicht',
        'must be more than': 'moet meer dan',
        'must be shorter than': 'moet minder dan',
        'must have at least': 'moet ten minste',
        'can only have': 'mag slechts',
        'is in an incorrect format': 'onjuist formaat',
        'Yes, I want to receive communications by e-mail about our services.': 'Ja, ik wens communicatie via e-mail ontvangen.',
        '*I have read and accept the privacy policy.': 'Ik heb het privacy beleid gelezen en accepteer het.',
        'characters': 'tekens',
        'cannot be validated': 'kan niet gevalideerd worden',
        'is invalid': 'is onjuist',
        'Business Workplace': 'Bedrijf',
        'Manufacturing & Industry': 'Fabriek & industrie',
        'Colleges & Universities': 'Hoge school & universiteit',
        'Health & Care': 'Gezondheid & zorg',
        'Defence': 'Defensie',
        'Retail': 'Retail',
        'Sports & Entertainment': 'Sport & events',
        'Destinations & Cultural Attractions': 'Culturele attracties',
        'Extractive Industries': 'Extractie-industrie',
        'Facilities Management': 'Facility Management',
        'Please correct the following errors:': 'Verbeter volgende fouten a.u.b. :',
        //myPayForm
        'Enter your former 9-digit Aramark Employee ID': 'Enter your former 9-digit Aramark Employee ID. If your Employee ID is less than 9-digits, enter it with a leading "0." For example, if your Employee ID is 12345678, enter 012345678. </br> If you do not remember your Aramark Employee ID, please contact Aramark Payment Services at US 615-864-9298 or CN 1- 800-729-5432 for additional support.',
        'Former Aramark Employee ID': 'Former Aramark Employee ID',
        'Current Email Address': 'Current Email Address',
        'Date of Birth': 'Date of Birth',
        'Select your Country': 'Select your Country',
        'US': 'US',
        'Canada': 'Canada',
        'Social Security Number': 'Social Security Number',
        'Please enter the last 4 digits of your SSN': 'Please enter the last 4 digits of your SSN',
        'Social Insurance Number': 'Social Insurance Number',
        'Please enter the last 3 digits of your SIN': 'Please enter the last 3 digits of your SIN',
        'My Pay Submit':'Verzenden'
      }
    ],
    en_cz: [
      {
        'Name': 'Name',
        'First Name': 'First Name',
        'Last Name': 'Last Name',
        'Organization': 'Organization',
        'Industry': 'Industry',
        'Email Address': 'Email Address',
        'Phone Number': 'Phone Number',
        'Comment / Questions': 'Comment / Questions',
        'Name of location or venue': 'Name of location or venue',
        'Zip / Postal Code': 'Zip/Postal Code',
        'Zip/Postal Code': 'Zip/Postal Code',
        'State': 'State',
        'Inquiry Type': 'Inquiry Type',
        'Customer, Invoice, or Order #': 'Customer, Invoice, or Order #',
        'Type of Services Received': 'Type of Services Received',
        'Tell us about your needs': 'Tell us about your needs',
        'Record Type': 'Record Type',
        'Submit': 'Submit',
        'Service Interested in': 'Service Interested in',
        'Yes, I want to receive communications by e-mail about our services.': 'Yes, I want to receive communications by e-mail about our services.',
        '*I have read and accept the privacy policy.': '*I have read and accept the privacy policy.',
        'is required': 'is required',
        'must be more than': 'must be more than',
        'must be shorter than': 'must be shorter than',
        'must have at least': 'must have at least',
        'can only have': 'can only have',
        'is in an incorrect format': 'is in an incorrect format',
        'characters': 'characters',
        'cannot be validated': 'cannot be validated',
        'is invalid': 'is invalid',
        'Business Workplace': 'Business Workplace',
        'Manufacturing & Industry': 'Manufacturing & Industry',
        'Colleges & Universities': 'Colleges & Universities',
        'Health & Care': 'Health & Care',
        'Defence': 'Defence',
        'Retail': 'Retail',
        'Sports & Entertainment': 'Sports & Entertainment',
        'Destinations & Cultural Attractions': 'Destinations & Cultural Attractions',
        'Extractive Industries': 'Extractive Industries',
        'Facilities Management': 'Facilities Management',
        'Please correct the following errors:': 'Please correct the following errors:',
        //myPayForm
        'Enter your former 9-digit Aramark Employee ID': 'Enter your former 9-digit Aramark Employee ID. If your Employee ID is less than 9-digits, enter it with a leading "0." For example, if your Employee ID is 12345678, enter 012345678. </br> If you do not remember your Aramark Employee ID, please contact Aramark Payment Services at US 615-864-9298 or CN 1- 800-729-5432 for additional support.',
        'Former Aramark Employee ID': 'Former Aramark Employee ID',
        'Current Email Address': 'Current Email Address',
        'Date of Birth': 'Date of Birth',
        'Select your Country': 'Select your Country',
        'US': 'US',
        'Canada': 'Canada',
        'Social Security Number': 'Social Security Number',
        'Please enter the last 4 digits of your SSN': 'Please enter the last 4 digits of your SSN',
        'Social Insurance Number': 'Social Insurance Number',
        'Please enter the last 3 digits of your SIN': 'Please enter the last 3 digits of your SIN',
        'My Pay Submit':'Submit'
      }
    ],
    cs_cz: [
      {
        'Name': 'Jméno',
        'First Name': 'Jméno',
        'Last Name': 'Příjmení',
        'Organization': 'Společnost/organizace',
        'Industry': 'Odvětví',
        'Email Address': 'E-mailová adresa',
        'Phone Number': 'Telefon',
        'Comment / Questions': 'Poznámka / dotaz',
        'Name of location or venue': 'Název místa nebo místa konání',
        'Zip / Postal Code': 'PSČ',
        'Zip/Postal Code': 'PSČ',
        'State': 'Stát',
        'Inquiry Type': 'Země',
        'Customer, Invoice, or Order #': 'Zákazník, faktura nebo objednávka #',
        'Type of Services Received': 'Typ přijatých služeb',
        'Tell us about your needs': 'Řekněte nám o svých potřebách',
        'Record Type': 'Typ záznamu',
        'Submit': 'Odeslat',
        'Service Interested in': 'Služba, o kterou máte zájem',
        'Yes, I want to receive communications by e-mail about our services.': 'Ano, chci dostávat e-mailem sdělení o službách společnosti.',
        '*I have read and accept the privacy policy.': '*Přečetl/a jsem si zásady ochrany osobních údajů a souhlasím s nimi.',
        'is required': 'je povinný údaj',
        'must be more than': 'musí být delší než',
        'must be shorter than': 'musí být kratší než',
        'must have at least': 'musí obsahovat alespoň',
        'can only have': 'může obsahovat pouze ',
        'is in an incorrect format': 'je ve špatném formátu',
        'characters': 'znaky',
        'cannot be validated': 'nelze ověřit',
        'is invalid': 'je neplatné',
        'Business Workplace': 'Pracoviště',
        'Manufacturing & Industry': 'Výrobní průmysl',
        'Colleges & Universities': 'Školní instituce',
        'Health & Care': 'Zdravotnictví a péče',
        'Defence': 'Armáda',
        'Retail': 'Maloobchodní prodej',
        'Sports & Entertainment': 'Sportovní akce',
        'Destinations & Cultural Attractions': 'Kultura',
        'Extractive Industries': 'Těžební průmysl',
        'Facilities Management': 'Facilities Management',
        'Please correct the following errors:': 'Opravte prosím následující chyby:',
        //myPayForm
        'Enter your former 9-digit Aramark Employee ID':'Enter your former 9-digit Aramark Employee ID. If your Employee ID is less than 9-digits, enter it with a leading "0." For example, if your Employee ID is 12345678, enter 012345678. </br> If you do not remember your Aramark Employee ID, please contact Aramark Payment Services at US 615-864-9298 or CN 1- 800-729-5432 for additional support.',
        'Former Aramark Employee ID': 'Former Aramark Employee ID',
        'Current Email Address': 'Current Email Address',
        'Date of Birth': 'Date of Birth',
        'Select your Country': 'Select your Country',
        'US': 'US',
        'Canada': 'Canada',
        'Social Security Number': 'Social Security Number',
        'Please enter the last 4 digits of your SSN': 'Please enter the last 4 digits of your SSN',
        'Social Insurance Number': 'Social Insurance Number',
        'Please enter the last 3 digits of your SIN': 'Please enter the last 3 digits of your SIN',
        'My Pay Submit':'Odeslat'
      }
    ],
    en_ar: [
      {
        'Name': 'Name',
        'First Name': 'First Name',
        'Last Name': 'Last Name',
        'Organization': 'Organization',
        'Industry': 'Industry',
        'Email Address': 'Email Address',
        'Phone Number': 'Phone Number',
        'Comment / Questions': 'Comment / Questions',
        'Name of location or venue': 'Name of location or venue',
        'Zip / Postal Code': 'Zip/Postal Code',
        'Zip/Postal Code': 'Zip/Postal Code',
        'State': 'State',
        'Inquiry Type': 'Inquiry Type',
        'Customer, Invoice, or Order #': 'Customer, Invoice, or Order #',
        'Type of Services Received': 'Type of Services Received',
        'Tell us about your needs': 'Tell us about your needs',
        'Record Type': 'Record Type',
        'Submit': 'Submit',
        'Service Interested in': 'Service Interested in',
        'Yes, I want to receive communications by e-mail about our services.': 'Yes, I would like to receive communications and give hereby my consent to the processing of my personal data for this purpose. I am aware that I can revoke my consent for the future at any time by emailing vega-judith@aramark.cl. For more information, please see the privacy notice',
        '*I have read and accept the privacy policy.': '*I have read and accept the privacy policy.',
        'is required': 'is required',
        'must be more than': 'must be more than',
        'must be shorter than': 'must be shorter than',
        'must have at least': 'must have at least',
        'can only have': 'can only have',
        'is in an incorrect format': 'is in an incorrect format',
        'characters': 'characters',
        'cannot be validated': 'cannot be validated',
        'is invalid': 'is invalid',
        'Business Workplace': 'Business Workplace',
        'Manufacturing & Industry': 'Manufacturing & Industry',
        'Colleges & Universities': 'Colleges & Universities',
        'Health & Care': 'Health & Care',
        'Defence': 'Defence',
        'Retail': 'Retail',
        'Sports & Entertainment': 'Sports & Entertainment',
        'Destinations & Cultural Attractions': 'Destinations & Cultural Attractions',
        'Extractive Industries': 'Extractive Industries',
        'Facilities Management': 'Facilities Management',
        'Please correct the following errors:': 'Please correct the following errors:',
        //myPayForm
        'Enter your former 9-digit Aramark Employee ID': 'Enter your former 9-digit Aramark Employee ID. If your Employee ID is less than 9-digits, enter it with a leading "0." For example, if your Employee ID is 12345678, enter 012345678. </br> If you do not remember your Aramark Employee ID, please contact Aramark Payment Services at US 615-864-9298 or CN 1- 800-729-5432 for additional support.',
        'Former Aramark Employee ID': 'Former Aramark Employee ID',
        'Current Email Address': 'Current Email Address',
        'Date of Birth': 'Date of Birth',
        'Select your Country': 'Select your Country',
        'US': 'US',
        'Canada': 'Canada',
        'Social Security Number': 'Social Security Number',
        'Please enter the last 4 digits of your SSN': 'Please enter the last 4 digits of your SSN',
        'Social Insurance Number': 'Social Insurance Number',
        'Please enter the last 3 digits of your SIN': 'Please enter the last 3 digits of your SIN',
        'My Pay Submit':'Submit'
      }
    ],
    es_ar: [
      {
        'Name': 'Nombre',
        'First Name': 'Nombre',
        'Last Name': 'Apellido',
        'Organization': 'Nombre de la Empresa',
        'Industry': 'Tipo de negocio',
        'Email Address': 'Dirección de correo electrónico',
        'Phone Number': 'Número de teléfono',
        'Comment / Questions': 'Mensaje / Preguntas',
        'Name of location or venue': 'Nombre del lugar o lugar',
        'Zip / Postal Code': 'Provincia',
        'Zip/Postal Code': 'Provincia',
        'State': 'Ciudad',
        'Inquiry Type': 'Tipo de pregunta',
        'Customer, Invoice, or Order #': 'Cliente, factura o pedido #',
        'Type of Services Received': 'Tipo de servicios recibidos',
        'Tell us about your needs': 'Cuéntenos sobre sus necesidades',
        'Record Type': 'Tipo de registro',
        'Submit': 'Enviar',
        'Service Interested in': 'Servicio Interesado En',
        'Yes, I want to receive communications by e-mail about our services.': 'Sí, quiero recibir comunicaciones por e-mail de sus servicios.',
        '*I have read and accept the privacy policy.': '*He leído y acepto el aviso de privacidad.',
        'is required': '*requerido',
        'must be more than': 'el campo debe ser mayor que',
        'must be shorter than': 'el campo debe ser menor que',
        'must have at least': 'el campo debe tener al menos',
        'can only have': 'el campo solo puede tener',
        'is in an incorrect format': 'Formato incorrecto',
        'characters': 'caracteres',
        'cannot be validated': 'no puede ser validado',
        'is invalid': 'no es válido',
        'Business Workplace': 'Empresas',
        'Manufacturing & Industry': 'Industrias',
        'Colleges & Universities': 'Colegios',
        'Health & Care': 'Salud',
        'Defence': 'Minería y sitios remotos',
        'Retail': 'Retail',
        'Sports & Entertainment': 'Sports & Entertainment',
        'Destinations & Cultural Attractions': 'Destinations & Cultural Attractions',
        'Extractive Industries': 'Extractive Industries',
        'Facilities Management': 'Facilities Management',
        'Please correct the following errors:': 'Por favor arreglar los siguientes errores:',
        //myPayForm
        'Enter your former 9-digit Aramark Employee ID': 'Enter your former 9-digit Aramark Employee ID. If your Employee ID is less than 9-digits, enter it with a leading "0." For example, if your Employee ID is 12345678, enter 012345678. </br> If you do not remember your Aramark Employee ID, please contact Aramark Payment Services at US 615-864-9298 or CN 1- 800-729-5432 for additional support.',
        'Former Aramark Employee ID': 'Former Aramark Employee ID',
        'Current Email Address': 'Current Email Address',
        'Date of Birth': 'Date of Birth',
        'Select your Country': 'Select your Country',
        'US': 'US',
        'Canada': 'Canada',
        'Social Security Number': 'Social Security Number',
        'Please enter the last 4 digits of your SSN': 'Please enter the last 4 digits of your SSN',
        'Social Insurance Number': 'Social Insurance Number',
        'Please enter the last 3 digits of your SIN': 'Please enter the last 3 digits of your SIN',
        'My Pay Submit': 'Enviar'
      }
    ],
    en_mx: [
      {
        'Name': 'Name',
        'First Name': 'First Name',
        'Last Name': 'Last Name',
        'Organization': 'Organization',
        'Industry': 'Industry',
        'Email Address': 'Email Address',
        'Phone Number': 'Phone Number',
        'Comment / Questions': 'Comment / Questions',
        'Name of location or venue': 'Name of location or venue',
        'Zip / Postal Code': 'Zip/Postal Code',
        'Zip/Postal Code': 'Zip/Postal Code',
        'State': 'State',
        'Inquiry Type': 'Inquiry Type',
        'Customer, Invoice, or Order #': 'Customer, Invoice, or Order #',
        'Type of Services Received': 'Type of Services Received',
        'Tell us about your needs': 'Tell us about your needs',
        'Record Type': 'Record Type',
        'Submit': 'Submit',
        'Service Interested in': 'Service Interested in',
        'Yes, I want to receive communications by e-mail about our services.': 'Yes, I would like to receive communications and give hereby my consent to the processing of my personal data for this purpose. I am aware that I can revoke my consent for the future at any time by emailing vega-judith@aramark.cl. For more information, please see the privacy notice',
        '*I have read and accept the privacy policy.': '*I have read and accept the privacy policy.',
        'is required': 'is required',
        'must be more than': 'must be more than',
        'must be shorter than': 'must be shorter than',
        'must have at least': 'must have at least',
        'can only have': 'can only have',
        'is in an incorrect format': 'is in an incorrect format',
        'characters': 'characters',
        'cannot be validated': 'cannot be validated',
        'is invalid': 'is invalid',
        'Business Workplace': 'Business Workplace',
        'Manufacturing & Industry': 'Manufacturing & Industry',
        'Colleges & Universities': 'Colleges & Universities',
        'Health & Care': 'Health & Care',
        'Defence': 'Defence',
        'Retail': 'Retail',
        'Sports & Entertainment': 'Sports & Entertainment',
        'Destinations & Cultural Attractions': 'Destinations & Cultural Attractions',
        'Extractive Industries': 'Extractive Industries',
        'Facilities Management': 'Facilities Management',
        'Please correct the following errors:': 'Please correct the following errors:',
        //myPayForm
        'Enter your former 9-digit Aramark Employee ID': 'Enter your former 9-digit Aramark Employee ID. If your Employee ID is less than 9-digits, enter it with a leading "0." For example, if your Employee ID is 12345678, enter 012345678. </br> If you do not remember your Aramark Employee ID, please contact Aramark Payment Services at US 615-864-9298 or CN 1- 800-729-5432 for additional support.',
        'Former Aramark Employee ID': 'Former Aramark Employee ID',
        'Current Email Address': 'Current Email Address',
        'Date of Birth': 'Date of Birth',
        'Select your Country': 'Select your Country',
        'US': 'US',
        'Canada': 'Canada',
        'Social Security Number': 'Social Security Number',
        'Please enter the last 4 digits of your SSN': 'Please enter the last 4 digits of your SSN',
        'Social Insurance Number': 'Social Insurance Number',
        'Please enter the last 3 digits of your SIN': 'Please enter the last 3 digits of your SIN',
        'My Pay Submit':'Submit'
      }
    ],
    es_mx: [
      {
        'Name': 'Nombre',
        'First Name': 'Nombre',
        'Last Name': 'Apellido',
        'Organization': 'Nombre de la Empresa',
        'Industry': 'Tipo de negocio',
        'Email Address': 'Dirección de correo electrónico',
        'Phone Number': 'Número de teléfono',
        'Comment / Questions': 'Mensaje / Preguntas',
        'Name of location or venue': 'Nombre del lugar o lugar',
        'Zip / Postal Code': 'Municipio',
        'Zip/Postal Code': 'Municipio',
        'State': 'Ciudad',
        'Inquiry Type': 'Tipo de pregunta',
        'Customer, Invoice, or Order #': 'Cliente, factura o pedido #',
        'Type of Services Received': 'Tipo de servicios recibidos',
        'Tell us about your needs': 'Cuéntenos sobre sus necesidades',
        'Record Type': 'Tipo de registro',
        'Submit': 'Enviar',
        'Service Interested in': 'Servicio Interesado En',
        'Yes, I want to receive communications by e-mail about our services.': 'Sí, quiero recibir comunicaciones por e-mail de sus servicios.',
        '*I have read and accept the privacy policy.': '*He leído y acepto el aviso de privacidad.',
        'is required': '*requerido',
        'must be more than': 'el campo debe ser mayor que',
        'must be shorter than': 'el campo debe ser menor que',
        'must have at least': 'el campo debe tener al menos',
        'can only have': 'el campo solo puede tener',
        'is in an incorrect format': 'Formato incorrecto',
        'characters': 'caracteres',
        'cannot be validated': 'no puede ser validado',
        'is invalid': 'no es válido',
        'Business Workplace': 'Empresas',
        'Manufacturing & Industry': 'Industrias',
        'Colleges & Universities': 'Colegios',
        'Health & Care': 'Salud',
        'Defence': 'Minería y sitios remotos',
        'Retail': 'Retail',
        'Sports & Entertainment': 'Sports & Entertainment',
        'Destinations & Cultural Attractions': 'Destinations & Cultural Attractions',
        'Extractive Industries': 'Extractive Industries',
        'Facilities Management': 'Facilities Management',
        'Please correct the following errors:': 'Por favor arreglar los siguientes errores:',
        //myPayForm
        'Enter your former 9-digit Aramark Employee ID': 'Enter your former 9-digit Aramark Employee ID. If your Employee ID is less than 9-digits, enter it with a leading "0." For example, if your Employee ID is 12345678, enter 012345678. </br> If you do not remember your Aramark Employee ID, please contact Aramark Payment Services at US 615-864-9298 or CN 1- 800-729-5432 for additional support.',
        'Former Aramark Employee ID': 'Former Aramark Employee ID',
        'Current Email Address': 'Current Email Address',
        'Date of Birth': 'Date of Birth',
        'Select your Country': 'Select your Country',
        'US': 'US',
        'Canada': 'Canada',
        'Social Security Number': 'Social Security Number',
        'Please enter the last 4 digits of your SSN': 'Please enter the last 4 digits of your SSN',
        'Social Insurance Number': 'Social Insurance Number',
        'Please enter the last 3 digits of your SIN': 'Please enter the last 3 digits of your SIN',
        'My Pay Submit':'Enviar'
      }
    ]
  }
}

export default Translation
